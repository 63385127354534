<template>
  <v-dialog
    v-model="dialogoUsuario"
    persistent
    max-width="800px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        text
        color="#224BFB"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          left
          dark
          color="#224BFB"
        >
          engineering
        </v-icon>
        Usuários
      </v-btn>  
    </template>
    <template #default="dialog">
      <v-card>
        <v-toolbar
          color="segunda"
          dark
        >
          <v-toolbar-title>
            <v-toolbar-items>Gestão de Usuários </v-toolbar-items>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              class="mx-2"
              icon
            >
              <v-icon
                dark
                large
                @click="dialog.value = false"
              >
                cancel
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        
        <v-card-text>
          <template>
            <v-row class="mt-8">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <h2>Editar usuário atual</h2>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="usuarioAtual.name"
                  dense
                  type="text"
                  name="usuario"
                  outlined
                  hint="Digite o nome do usuário"
                  label="Nome do Usuário"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="usuarioAtual.email"
                  dense
                  outlined
                  type="email"
                  name="email"
                  append-icon="email"
                  :rules="[rules.required, rules.email]"
                  hint="Digite o e-mail do usuário"
                  label="E-mail do Usuário"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="usuarioAtual.password"
                  dense
                  outlined
                  :append-icon="trocarIconeLoginAtual ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="trocarIconeLoginAtual ? 'text' : 'password'"
                  name="password"
                  label="Nova Senha"
                  class="input-group--focused"
                  @click:append="trocarIconeLoginAtual = !trocarIconeLoginAtual"
                />
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  dense
                  v-model="usuarioAtual.confirmPassword"
                  outlined
                  :append-icon="trocarIconeLoginAtual ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.emailMatchAtual]"
                  :type="trocarIconeLoginAtual ? 'text' : 'password'"
                  name="confirmPassword"
                  label="Cofirmação de Senha"
                  hint="Confirme a senha do usuário"
                  class="input-group--focused"
                  @click:append="trocarIconeLoginAtual = !trocarIconeLoginAtual"
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-btn
                  block
                  large
                  color="segunda"
                  class="sexta--text"
                  @click="alterarUserAtual"
                >
                  <v-icon left>
                    mdi-clipboard-check-outline
                  </v-icon>SALVAR ALTERAÇÃO
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-text>

        <v-card-text>
          <usuarios
            :usuario-atual-na-tabela="usuarioAtual"
            :inf-usuarios="infUsuarios"
          />
        </v-card-text>
        
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog.value = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios"
import barramento from "@/barramento"
import { urlApi, showError, userKey } from "@/global"
import usuarios from "./usuarios";
export default {
  name: "ModalGestaoUsuario",
  components: { usuarios },
  data: function(){
      return{
          dialogoUsuario: false,
          infUsuarios: [],
          usuarioAtual: {},
          trocarIconeLoginAtual: false,
          rules: {
            required: value => !!value || 'Campo obrigatório.',
            min: v => {
                if(v == undefined){
                  return 'Digite uma senha'
                    }else{
                      return v.length >= 8 || 'Min de 8 caracteres'
                    }
                },
            emailMatchAtual: val => val == this.usuarioAtual.password || 'Senhas não coincidem',
            email: value => {
                  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  return pattern.test(value) || 'Email inválido.'
                  }
          },
      }
  },
   watch:{
      dialogoUsuario(val){
          this.getUsuarios()
          this.getUserAtual()
      }
  },
  created() {
    barramento.getNovosUsuarios(novosUser=>{
      if(novosUser)this.getUsuarios()
    })
  },
  methods:{
    getUsuarios() {
      axios.get(`${urlApi}/users`)
      .then(res => {
          this.infUsuarios = res.data
      })
      .catch(showError)
    },
    getUserAtual(){
      const jsonUserAtual = localStorage.getItem(userKey)
			const userDataAtual = JSON.parse(jsonUserAtual)
      this.usuarioAtual = userDataAtual
    },
    alterarUserAtual() {
      axios.patch(`${urlApi}/users/${this.usuarioAtual.id}`, this.usuarioAtual)
      .then(() => {
          this.getUsuarios()
          this.$toasted.global.defaultSuccess()})
      .catch(showError)
    },
  }
};
</script>
<style>
</style>