<template>
  <div class="c-oportunidade">
    <h3>
      <v-icon left>description</v-icon>Oportunidade
      <v-scale-transition>
        <v-btn
          absolute
          right
          v-if="itemModificado == 'oportunidade'"
          outlined
          color="segunda"
          class="ma-2 white--text mt-n3"
          @click="ePublicarOportunidade()"
        >
          PUBLICAR
          <v-icon right dark>save</v-icon>
        </v-btn>
      </v-scale-transition>
    </h3>
    <VueEditor
      :editorToolbar="customToolbar"
      @selection-change="liberarPublicar()"
      @text-change="itemModificado = publicarNovoTexto"
      id="editorFomCol1"
      v-model="ContentOportunidade"
    ></VueEditor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "OportunidadeScreen",
  components: { VueEditor },
  props: {
    textOp: String,
    dialogoEventOpt: Boolean
  },
  data: function() {
    return {
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike", "link"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // sobrescrito/subscrito
        [{ indent: "-1" }, { indent: "+1" }], // recuo/recuo

        [{ color: [] }, { background: [] }], // dropdown com padrões
        ["clean"]
      ],
      ContentOportunidade: this.textOp,
      itemModificado: '',
      publicarNovoTexto: ''
    };
  },

  watch:{
    dialogoEventOpt(el){
      if(el){
        this.ContentOportunidade = this.textOp
      }else{
        this.ContentOportunidade = ''
      }
    }
  },
  created(){

  },
  mounted() {

  },
  methods: {
    liberarPublicar() {
      this.publicarNovoTexto = "oportunidade";
    },
    ePublicarOportunidade(){
          let cTextoOportunidade = this.ContentOportunidade;
          this.$emit("receberTextOpt", cTextoOportunidade);
          this.itemModificado = ''
      },
  }
};
</script>

<style>
</style>