<template>
  <v-card color="sexta">
    <v-card-title>
      <v-sheet
        elevation="0"
        color="sexta"
        width="100%"
        class="my-2 pa-2 c-titulo-op-fase"
        rounded
      >
        <v-btn
          absolute
          right
          top
          fab
          small
          class="mt-7 mr-n1"
          @click="clouseOpFase()"
        >
          <v-icon color="segunda">
            close
          </v-icon>
        </v-btn>
        <h3 class="segunda--text font-weight-regular font-weight-bold">
          <v-icon
            large
            color="segunda"
            class="pr-2"
          >
            settings_suggest
          </v-icon>ADICIONAR CAMPOS A FASE
        </h3>
        <hr
          color="segunda"
          class="mt-3"
        >
      </v-sheet>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          class="pt-5 pb-n5"
        >
          <v-text-field
            v-model="dataCampos.nomeFase"
            class="c-dataCampos-nomeFase mt-4"
            color="segunda"
            outlined
            autofocus
            :rules="[rulesCheckbox.nomeFase]"
            label="NOME DA FASE"
            hint="Pressione enter após alterar o nome da fase"
            @change="nomeFaseFoiAlterado()"
            @keyup.enter="salvarNomeFaseOp()"
          />
          <v-switch
            v-model="dataCampos.anexo"
            class="c-dataCampos-TextoCurto__opcao"
            label="Adicionar a opção de anexar arquivos ?"
            @change="desativarFaseBackup($event, 'anexos')"
          />
          <v-expand-transition>
            <v-col
              v-if="dataCampos.anexo"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="dataCampos.anexoNome"
                class="c-dataCampos-TextoCurto__titulo"
                autofocus
                :rules="[rulesCheckbox.requiredAnexo]"
                label="Nome do campo"
              />
              <v-text-field
                v-model="dataCampos.anexoObs"
                dense
                color="segunda"
                label="Observações do campo"
                filled
              />
            </v-col>
          </v-expand-transition>
          <v-switch
            v-model="dataCampos.textoCurtoOpcao"
            class="c-dataCampos-TextoCurto__opcao"
            label="Adicionar texto curto ?"
            @change="desativarFaseBackup($event, 'textoCurto')"
          />
          <v-expand-transition>
            <v-col
              v-if="dataCampos.textoCurtoOpcao"
              class="my-0 py-0"
            >
              <v-text-field
                v-model="dataCampos.textoCurtoTitulo"
                class="c-dataCampos-TextoCurto__titulo"
                autofocus
                :rules="[rulesCheckbox.requiredTitulo]"
                label="Nome do campo"
              />
              <v-text-field
                v-model="dataCampos.textoCurtoObs"
                dense
                color="segunda"
                label="Observações do campo"
                filled
              />
            </v-col>
          </v-expand-transition>
          <v-switch
            v-model="dataCampos.checkBoxOpcao"
            class="c-dataCampos-Checkbox__opcao"
            label="Adicionar checkboxs ?"
            @change="desativarFaseBackup($event, 'checkbox')"
          />
          <v-expand-transition>
            <v-col
              v-if="dataCampos.checkBoxOpcao"
              class="my-0 py-0"
            >
              <v-expansion-panels
                v-model="painelAberto"
                inset
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>Checkbox</v-expansion-panel-header>
                  <v-expansion-panel-content class="c-dataCampos-checkBox">
                    <v-checkbox
                      v-model="dataCampos.checkBoxIsObrigatorio"
                      label="Este campo é obrigatório?"
                      color="segunda"
                      :value="false"
                    />
                    <v-text-field
                      v-model="dataCampos.checkBoxTitulo"
                      class="c-dataCampos-CheckBox__titulo"
                      autofocus
                      label="Título do campo"
                      :rules="[rulesCheckbox.required]"
                    />
                    <v-text-field
                      v-model="dataCampos.checkBoxObs"
                      dense
                      color="segunda"
                      label="Observações do campo"
                      filled
                    />
                    <div class="c-dataCampos-CheckBox__campos">
                      <h3 class="mb-3">
                        Adicione as opções
                      </h3>
                      <div
                        v-for="(cpCheckbox, i) in dataCampos.checkboxCamposIni"
                        :key="i"
                        class="c-dataCampos-Checkbox__campo"
                        :class="`c-dataCampos-Checkbox__${i}`"
                      >
                        <v-text-field
                          v-model="dataCampos.checkboxCampos[i]"
                          clearable
                          :label="'Opção ' + JSON.stringify(i + 1)"
                          :rules="[rulesCheckbox.required]"
                          append-outer-icon="delete_forever"
                          @click:append-outer="deleteOp(i)"
                          @change="addCampo($event, i)"
                        />
                      </div>
                      <v-icon
                        color="segunda"
                        class="float-right"
                        x-large
                        @click="addOpcaoCheckbox()"
                      >
                        add_circle
                      </v-icon>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-expand-transition>
          <v-switch
            v-model="dataCampos.radioOpcao"
            class="c-dataCampos-Radio__opcao"
            label="Adicionar seleção de única opção ?"
            @change="desativarFaseBackup($event, 'radio')"
          />
          <v-expand-transition>
            <v-col
              v-if="dataCampos.radioOpcao"
              class="my-0 py-0"
            >
              <v-expansion-panels
                v-model="painelRadio"
                inset
                multiple
              >
                <v-expansion-panel>
                  <v-expansion-panel-header>Seleção de única opção</v-expansion-panel-header>
                  <v-expansion-panel-content class="c-dataCampos-radio">
                    <v-checkbox
                      v-model="dataCampos.radioObrigatorio"
                      label="Este campo é obrigatório?"
                      color="segunda"
                      :value="false"
                    />
                    <v-text-field
                      v-model="dataCampos.radioTitulo"
                      class="c-dataCampos-Radio__titulo"
                      autofocus
                      label="Título do campo"
                      :rules="[rulesCheckbox.radioRequired]"
                    />
                    <v-text-field
                      v-model="dataCampos.radioObs"
                      dense
                      color="segunda"
                      label="Observações do campo"
                      filled
                    />
                    <div class="c-dataCampos-Radio__campos">
                      <h3 class="mb-3">
                        Adicione as opções
                      </h3>
                      <div
                        v-for="(cpRadio, idRadio) in dataCampos.radioCamposIni"
                        :key="idRadio"
                        class="c-dataCampos-Radio__campo"
                        :class="`c-dataCampos-Radio__${idRadio}`"
                      >
                        <v-text-field
                          v-model="dataCampos.radioCampos[idRadio]"
                          clearable
                          :label="'Opção ' + JSON.stringify(idRadio + 1)"
                          :rules="[rulesCheckbox.radioRequired]"
                          append-outer-icon="delete_forever"
                          @click:append-outer="deleteOpRadio(idRadio)"
                          @change="addCampoRadio($event, idRadio)"
                        />
                      </div>
                      <v-icon
                        color="segunda"
                        class="float-right"
                        x-large
                        @click="addOpcaoRadio()"
                      >
                        add_circle
                      </v-icon>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-expand-transition>
          <v-switch
            v-model="dataCampos.integracaoSisPregao"
            class="c-dataCampos-Checkbox__opcao"
            label="Integrar a Fase com Sistema de Pregão?"
            @change="desativarIntegracao($event)"
          />
          <v-scroll-y-reverse-transition>
            <v-col
              v-if="dataCampos.integracaoSisPregao"
              class="ma-0 pa-0"
            >
              <v-expansion-panels
                v-model="painelIntegracao"
                flat
                inset
                multiple
                class="ma-0 pa-0"
              >
                <v-expansion-panel>
                  <v-expansion-panel-content color="sexta">
                    <autenticaPregao
                      :dados-integracao="dataCampos.integracao"
                      @getAutenSistPregao="autSistPregao($event)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-scroll-y-reverse-transition>
          <v-switch
            v-model="dataCampos.faseBackup"
            class="c-dataCampos-Checkbox__opcao"
            label="Fase de Backup ?"
            hint="Deseja tornar está fase em uma fase de backup?"
            persistent-hint
            @change="ativarFaseBackup($event)"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        color="nona"
        class="sexta--text"
        @click="clouseOpFase()"
      >
        CANCELAR
      </v-btn>
      <v-btn
        v-if="dataCampos.anexo || dataCampos.textoCurtoOpcao || dataCampos.checkBoxOpcao || dataCampos.radioOpcao || dataCampos.faseBackup || alterouNomeFase || cpAlterados || dataCampos.integracaoSisPregao"
        color="primeira"
        class="sexta--text"
        @click="addCamposFase()"
      >
        SALVAR CAMPOS
      </v-btn>
      <v-btn
        v-else
        disabled
        color="primeira"
        class="sexta--text"
        @click="addCamposFase()"
      >
        SALVAR CAMPOS
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import autenticaPregao from "./autenticaPregao";
export default {
  name: "OpFase",
  components: {
    autenticaPregao
  },
  props: {
    posicaoDaFase: Number,
    idFaseClicada: Number,
    opFaseClicada: [Object, Boolean],
    isdialogOpFase: Boolean,
    nomeFaseOpcoes: String,
    ordenacaoCards: Boolean
  },
  data: function() {
    return {
      dialogoIsResetarOp: false,
      opAlterada: "",
      painelAberto: [0],
      painelRadio: [0],
      painelIntegracao: [0],
      isMudancas: false,
      dataCampos: {
        checkBoxOpcao: false,
        textoCurtoOpcao: false,
        radioOpcao: false,
        anexo: false,
        faseBackup: false,
        checkBoxObs: "",
        radioObs: "",
        textoCurtoObs: "",
        anexoObs: "",
        anexoNome: "",
        textoCurtoTitulo: "",
        checkBoxTitulo: "",
        radioTitulo: "",
        checkboxCampos: [""],
        radioCampos: [""],
        checkboxCamposIni: [""],
        radioCamposIni: [""],
        nomeFase: this.nomeFaseOpcoes,
        filtrosFase: { ativo: false, mensagem: "" },
        porMaisAntigo: this.ordenacaoCards,
        integracao: {
          sistemPregao: {
            ativa: false,
            acesso: {}
          }
        },
        integracaoSisPregao: false
      },
      validChecbox: false,
      rulesCheckbox: {
        required: value => !!value || "Valor não pode estar vazio.",
        requiredTitulo: value => !!value || "Valor não pode estar vazio.",
        requiredAnexo: value => !!value || "Valor não pode estar vazio.",
        radioRequired: value => !!value || "Valor não pode estar vazio.",
        nomeFase: value => !!value || "Valor não pode estar vazio."
      },
      alterouNomeFase: false,
      cpAlterados: false
    };
  },
  watch: {
    isdialogOpFase(val) {
      if (!!val) {
        this.getFaseOp();
      }
    }
  },
  mounted() {
    this.getFaseOp();
  },
  methods: {
    autSistPregao(event) {
      this.dataCampos.integracao.sistemPregao = event;
    },
    qualFiltroAtivo(op1, op2) {
      if (!op1 && !op2) {
        return false;
      }
      if (op1 && op2) {
        return "ambos";
      } else {
        if (!op1) {
          return "radio";
        } else {
          return "checkBox";
        }
      }
    },
    resetarOpcoes(opcao, isResetar) {
      if (isResetar) {
        let opParaResetar = {};
        opParaResetar.op = opcao;
        opParaResetar.pFase = this.posicaoDaFase;
        opParaResetar.idFase = this.idFaseClicada;
        this.$emit("resetarOpcao", opParaResetar);
        this.dialogoIsResetarCp = false;
        this.opAlterada = "";
      } else {
        this.dialogoIsResetarOp = false;
        this.opAlterada = "";
      }
    },
    getFaseOp() {
      axios
        .get(`${urlApi}/fase/getFaseIdOp/${this.idFaseClicada}`)
        .then(res => {
          this.dataCampos = res.data.opcoes;
        })
        .catch(msg => {
          this.$toasted.global.defaultError({
            msg: "Verifique as opções da fase!"
          });
        });
    },
    salvarNomeFaseOp() {
      if (this.alterouNomeFase) this.addCamposFase();
    },
    nomeFaseFoiAlterado() {
      this.alterouNomeFase = true;
    },
    desativarIntegracao(event) {
      this.cpAlterados = true;
      if (event) {
        if (this.dataCampos.faseBackup) {
          this.dataCampos.faseBackup = false;
        }
      }
    },
    desativarFaseBackup(event, opcao) {
      this.cpAlterados = true;
      if (this.dataCampos.faseBackup) {
        this.dataCampos.faseBackup = false;
      }
      if (event) {
        this.opAlterada = opcao;
        this.resetarOpcoes(this.opAlterada, event);
      }
    },
    ativarFaseBackup($event) {
      this.cpAlterados = true;
      if (this.dataCampos.faseBackup) {
        this.dataCampos.anexo = false;
        this.dataCampos.textoCurtoOpcao = false;
        this.dataCampos.checkBoxOpcao = false;
        this.dataCampos.radioOpcao = false;
        this.dataCampos.integracaoSisPregao = false;
      }
    },
    clouseOpFase() {
      this.$emit("fecharOpFase", this.reset);
    },
    atulizarOpcaoDaFase(op) {
      this.$emit("atulizarOpcoesDaFase", op);
    },
    addCamposFase() {
      this.dataCampos.idFase = this.idFaseClicada;
      this.dataCampos.posicaoFase = this.posicaoDaFase;
      this.dataCampos.porMaisAntigo = this.ordenacaoCards;

      if (this.dataCampos.checkBoxOpcao || this.dataCampos.radioOpcao) {
        let qualFiltro = this.qualFiltroAtivo(
          this.dataCampos.checkBoxIsObrigatorio,
          this.dataCampos.radioObrigatorio
        );

        if (qualFiltro === "checkBox") {
          this.dataCampos.filtrosFase = {
            ativo: true,
            mensagem: `O campo ${this.dataCampos.checkBoxTitulo} dentro do card precisa ser definido`,
            campo: "checkBox"
          };
        }

        if (qualFiltro === "radio") {
          this.dataCampos.filtrosFase = {
            ativo: true,
            mensagem: `O campo ${this.dataCampos.radioTitulo} dentro do card precisa ser definido`,
            campo: "radio"
          };
        }
        if (qualFiltro === "ambos") {
          this.dataCampos.filtrosFase = {
            ativo: true,
            mensagem:
              "Existe campos obrigatórios no card que precisam ser definidos",
            campo: "ambos"
          };
        }
        if (!qualFiltro) {
          this.dataCampos.filtrosFase = {
            ativo: false,
            mensagem: "",
            campo: undefined
          };
        }
      } else {
        this.dataCampos.filtrosFase = {
          ativo: false,
          mensagem: "",
          campo: undefined
        };
      }

      axios
        .post(`${urlApi}/atualizarCamposFase`, this.dataCampos)
        .then(res => {
          this.estadoInicial = res.data[0].opcoes;
          this.atulizarOpcaoDaFase(res.data[0].opcoes);
          this.dataCampos = {};
          this.dataCampos.checkBoxTitulo = "";
          this.dataCampos.checkboxCampos = [""];
          this.dataCampos.radioTitulo = "";
          this.dataCampos.radioCampos = [""];
          this.clouseOpFase();
          this.alterouNomeFase = false;
          this.cpAlterados = false;
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
    addCampo($event, indice) {
      if (event) {
        if (event.target._value) {
          this.dataCampos.checkboxCampos.splice(indice, 1, event.target._value);
        }
      }
    },
    addCampoRadio($event, indice) {
      if (!!event) {
        if (!!event.target._value) {
          this.dataCampos.radioCampos.splice(indice, 1, event.target._value);
        }
      }
    },
    deleteOp(opClicada) {
      this.dataCampos.checkboxCamposIni.splice(opClicada, 1);
      this.dataCampos.checkboxCampos.splice(opClicada, 1);
    },
    deleteOpRadio(opClicada) {
      this.dataCampos.radioCamposIni.splice(opClicada, 1);
      this.dataCampos.radioCampos.splice(opClicada, 1);
    },
    addOpcaoCheckbox() {
      this.dataCampos.checkboxCamposIni.push("");
      this.dataCampos.checkboxCampos.push("");
    },
    addOpcaoRadio() {
      this.dataCampos.radioCamposIni.push("");
      this.dataCampos.radioCampos.push("");
    }
  }
};
</script>

<style>
.c-dataCampos-Checkbox__0 .v-input__append-outer {
  display: none;
}
.c-dataCampos-Radio__0 .v-input__append-outer {
  display: none;
}
</style>
