<template>
  <v-scroll-y-reverse-transition>
    <v-card
      outlined
      color="sexta"
      class="mx-auto pt-4"
      max-width="344"
      flat
      elevation="1"
      shaped
    >
      <v-row class="d-flex flex-column pa-5">
        <v-img
          class="c-header__logo align-self-center"
          src="@/assets/logo-nutrimix-distribuidora.svg"
          contain
          max-height="auto"
          max-width="150"
        />
        <h3 class="pa-5 pb-1 primeira--text font-weight-bold text-center">
          Login de Autenticação
        </h3>
        <h3 class="pt-0 primeira--text font-weight-black text-center">
          Sistema Controle de Pregão
        </h3>
        <v-col class="my-0 mt-4">
          <v-text-field
            v-model="userAutenticar.email"
            dense
            outlined
            color="segunda"
            type="email"
            name="email"
            append-icon="email"
            :rules-autenticar="[rulesAutenticar.required, rulesAutenticar.email]"
            label="E-mail"
            :class="{autenticadoAtivo: isAutenticadoSisPregao}"
          />
        </v-col>

        <v-col class="mt-n5">
          <v-text-field
            outlined
            dense
            color="segunda"
            v-model="userAutenticar.password"
            :append-icon="iconeLoginTrocar ? 'mdi-eye' : 'mdi-eye-off'"
            :rules-autenticar="[rulesAutenticar.required, rulesAutenticar.min]"
            :type="iconeLoginTrocar ? 'text' : 'password'"
            name="password"
            label="Senha"
            class="input-group--focused"
            :class="{autenticadoAtivo: isAutenticadoSisPregao}"
            @click:append="iconeLoginTrocar = !iconeLoginTrocar"
            @keyup.enter="loginAutenticar"
          />
        </v-col>

        <v-col class="d-flex justify-space-between mt-n5 align-center">
          <v-switch
            v-if="isAutenticadoSisPregao"
            v-model="isAutenticadoSisPregao"
            class="c-switch__autenticado"
            color="green"
            label="AUTENTICADO"
          />
          <v-switch
            v-else
            v-model="isAutenticadoSisPregao"
            disabled
            class="c-switch__autenticado"
            color="green"
            label="AUTENTICADO"
          />

          <v-btn
            color="#224BFB"
            class="white--text"
            :class="{autenticadoAtivo: isAutenticadoSisPregao}"
            @click="loginAutenticar"
            @dblclick.prevent
          >
            AUTENTICAR-SE
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-scroll-y-reverse-transition>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey, rutPregao } from "@/global";
import barramento from "@/barramento";
export default {
  name: "AutenticaPregao",
  props: {
    dadosIntegracao: Object
  },
  data: function() {
    return {
      isAutenticadoSisPregao: false,
      userAutenticar: {},
      sysInt: {},
      iconeLoginTrocar: false,
      rulesAutenticar: {
        required: value => !!value || "Campo obrigatório.",
        min: v => {
          if (v == undefined) {
            return "Digite uma senha";
          } else {
            return v.length >= 8 || "Min de 8 caracteres";
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        }
      }
    };
  },
  created() {
    this.isAutenticadoSisPregao = this.dadosIntegracao.sistemPregao.ativa;
  },
  methods: {
    enviarAutenticacao(dataAutenticao) {
      this.$emit("getAutenSistPregao", dataAutenticao);
    },
    atIntegracao(dados) {
      axios
        .post(`${urlApi}/atIntegracao`, dados)
        .then(res => {
          this.isAutenticadoSisPregao = true;
          this.userAutenticar = {};
          this.sysInt = {};
        })
        .catch(showError);
    },
    loginAutenticar() {
      const keyPregao = "__controlePregao_user";
      axios
        .post(`${rutPregao}/signin`, this.userAutenticar)
        .then(res => {
          let sistemPregao = {
            ativa: true,
            acesso: {
              data: res.data,
              rutPregao: keyPregao
            }
          };

          this.enviarAutenticacao(sistemPregao);
          this.sysInt.integracoes = JSON.stringify(sistemPregao);
          this.sysInt.id = JSON.parse(localStorage.getItem(userKey)).id;

          this.atIntegracao(this.sysInt);

          this.$toasted.global.defaultSuccess({ msg: "Usuário Autenticado!" });
        })
        .catch(showError);
    }
  }
};
</script>
<style>
.c-switch__autenticado label {
  font-size: 13px !important;
  font-weight: 700;
}
.c-gestor-projeto-login .c-gestor-projeto-login-titulo {
  color: #ffffff;
  font-weight: 500;
}
.autenticadoAtivo {
    opacity: 0.5;
    pointer-events: none;
}
</style>