<template>
  <v-card flat class="c-views-card" color="dprimeira">
    <v-card-text class="ma-0 pa-0">
      <v-row no-gutters justify="space-between" class="dprimeira c-viewsDataCard ma-0 pa-0">
        <!-- Primeira Coluna -->
        <v-col cols="5" lg="5" md="5" sm="5" xs="5" class="mt-0 pt-0 c-views-col c-primeira-coluna">
          <v-text-field
            background-color="dprimeira"
            class="u-hover c-fase-views__titulo ma-4"
            ref="titulo"
            color="segunda"
            solo
            flat
            :value="cardClicadoAtual.titulo"
            @keydown="itemModificado = 'titulo'"
            @change="changeTitulo($event)"
            hide-details
          ></v-text-field>
          <v-sheet
            color="dprimeira"
            elevation="0"
            min-height="100%"
            width="100%"
            class="c-viewsDataCard-item"
          >
            <v-row>
              <v-col cols="5" lg="5" md="5" sm="5" xs="5" class="pl-10">
                    <v-text-field
                      color="segunda"
                      id="c-datetime-local"
                      v-model="dateFormattedCard"
                      @blur="dataVencimentoCard = dateFormattedCard"
                      type="datetime-local"
                      label="Vencimento"
                    ></v-text-field>
              </v-col>
              <v-col cols="4" lg="4" md="4" sm="4" xs="4" class="d-flex align-center mt-n2">
                <v-chip color="segunda" text-color="white">{{calcDiasRestanteLt(dateFormattedCard)}}</v-chip>
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mt-n3 pt-0">
                <v-card color="dprimeira">
                  <v-tabs
                    v-model="tabCol1"
                    grow
                    color="segunda"
                    tile
                    :elevation="0"
                    flat
                    class="tabColFormInicial"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab href="#tab-1">
                      <v-icon left>work</v-icon>Nova Oportunidade
                    </v-tab>

                    <v-tab href="#tab-2">
                      <v-icon left>attach_file</v-icon>Anexos
                    </v-tab>

                    <v-tab href="#tab-3">
                      <v-icon left>speaker_notes</v-icon>Comentários
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items class="c-content-tab__col1" v-model="tabCol1">
                    <v-tab-item :key="1" :value="'tab-' + 1">
                      <v-card flat color="dprimeira">
                        <v-card-text>
                          <h3>
                            <v-icon left>work</v-icon>Formulário Inicial
                          </h3>
                          <p>{{cardClicadoAtual.historicoFi}}</p>
                          <h3>
                            <v-icon left>assignment</v-icon>Número do Pregão / Comprador
                          </h3>
                          <p>{{cardClicadoAtual.titulo}}</p>
                          <oportunidade
                            :textOp="cardClicadoAtual.oportunidade"
                            :dialogoEventOpt="eventDialogo"
                            @receberTextOpt="atTextOpt($event, 'Oportunidade', 7, 'oportunidade')"
                          ></oportunidade>
                        </v-card-text>
                      </v-card>
                      <h3 class="dprimeira">
                        <v-icon left>article</v-icon>Histórico
                      </h3>
                      <v-row class="dprimeira">
                        <v-col>
                          <historico
                            :dialogoEventH="eventDialogo"
                            :listah="cardClicadoAtual.historico.hFase"
                          ></historico>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :key="2" :value="'tab-' + 2">
                      <v-row>
                        <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                          <v-card width="95%" flat>
                            <v-file-input
                              id="c-views-anexo-input"
                              :append-icon="enviarFileIcon"
                              clearable
                              full-width
                              color="segunda"
                              v-model="filesViews"
                              multiple
                              label="Anexar arquivos"
                              class="c-dados-cards__anexo-"
                              outlined
                              dense
                              @click:append="fileUpload('pc', 'Formulário Inicial', 1)"
                            ></v-file-input>
                            <h5
                              class="c-fase-views__titulos pl-4 segunda--text text-uppercase text-subtitle-1 font-weight-black d-flex align-center"
                            >
                              Anexos
                              <hr color="segunda" class="flex-grow-1 ml-2" />
                            </h5>
                            <v-switch
                              v-if="cardClicadoAtual.anexo.length > 0"
                              class="c-views-anexo__isVer pl-4"
                              label="Deseja excluir os anexos ?"
                              v-model="isExcluirAnexos"
                            ></v-switch>
                            <v-list
                              color="dprimeira"
                              v-if="cardClicadoAtual.anexo"
                              dense
                              class="c-views-anexo-fi"
                              :class="{'c-ver-anexos': isExcluirAnexos}"
                            >
                              <v-list-item-group v-model="viewsSelecFile" color="segunda">
                                <v-list-item
                                  v-for="(anexoFI, idAnexoFI) in cardClicadoAtual.anexo"
                                  :key="idAnexoFI"
                                  :class="anexoFI.coluna"
                                >
                                  <v-list-item-icon>
                                    <v-icon v-text="anexoFI.icone"></v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="anexoFI.nomeArquivo"
                                      @click="abrirArquivo(anexoFI.caminho, 'verLink')"
                                    />
                                    <v-list-item-subtitle
                                      class="d-inline-flex flex-wrap c-atividades-anexos-sbtitle"
                                    >
                                      <v-chip
                                        color="segunda"
                                        text-color="white"
                                        x-small
                                      >{{anexoFI.nomeCampo}}</v-chip>
                                      <v-chip
                                        color="segunda"
                                        text-color="white"
                                        x-small
                                      >{{formatDataAnexo(anexoFI.lastModifiedDate)}}</v-chip>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action v-if="isExcluirAnexos">
                                    <v-list-item-action-text
                                      @click="deletarArquivoUpado(anexoFI.caminho , idAnexoFI)"
                                    >
                                      <v-icon color="segunda" left>delete_forever</v-icon>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                  <v-list-item-action class="pl-3">
                                    <v-list-item-action-text
                                      @click="abrirArquivo(anexoFI.caminho, 'verLink')"
                                    >
                                      <v-icon color="segunda" left>open_in_new</v-icon>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                  <v-list-item-action>
                                    <v-list-item-action-text
                                      @click="downloadArquivo(anexoFI.caminho, anexoFI.tipo, anexoFI.nomeArquivo, 'download')"
                                    >
                                      <v-icon color="segunda" left>downloading</v-icon>
                                    </v-list-item-action-text>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab-item :key="3" :value="'tab-' + 3">
                      <listaComentario
                        :dialogoEventComent="eventDialogo"
                        :listaComent="cardClicadoAtual.atividades"
                      ></listaComentario>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <!-- Segunda Coluna -->
        <v-col cols="5" lg="5" md="5" sm="5" xs="5" class="mt-0 pt-0 c-views-col c-segunda-coluna">
          <v-text-field
            background-color="dprimeira"
            color="segunda"
            solo
            flat
            v-model="nomeFase"
            hide-details
            disabled
            class="c-fase-views__titulo ma-4"
          >
            <template v-slot:prepend-inner>
              <h5 class="c-fase-views__titulo__sub">Fase atual:</h5>
            </template>
          </v-text-field>
          <v-sheet
            color="dprimeira"
            elevation="0"
            width="100%"
            min-height="100%"
            class="c-viewsDataCard-item pt-4 px-2"
          >
            <v-row v-if="opFases" justify="start">
              <v-col
                v-if="opFases.anexo"
                class="c-views-anexo py-0"
                cols="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <v-text-field
                  color="segunda"
                  solo
                  flat
                  dense
                  :value="opFases.anexoNome"
                  :hint="opFases.anexoObs"
                  persistent-hint
                  readonly
                  class="c-fase-views__titulos"
                ></v-text-field>

                <v-card width="100%" flat class="pa-2" color="dprimeira">
                  <v-file-input
                    id="c-views-anexo-inputSC"
                    :append-icon="enviarFileIcon"
                    clearable
                    full-width
                    color="segunda"
                    v-model="filesViews"
                    multiple
                    label="Anexar arquivos"
                    class="c-dados-cards__anexo-"
                    outlined
                    dense
                    @click:append="fileUpload('sc', opFases.anexoNome, cardClicadoAtual.fase)"
                  ></v-file-input>
                  <v-list dense id="c-views-anexo-sc" color="dprimeira">
                    <v-list-item-group v-model="viewsSelecFile" color="segunda">
                      <v-list-item
                        v-for="(anexoSC, idAnexoSC) in apenasAnexosDaFase(cardClicadoAtual.anexo)"
                        :key="idAnexoSC"
                        :class="!!idAnexoSC"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="anexoSC.icone"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="anexoSC.nomeArquivo"
                            @click="abrirArquivo(anexoSC.caminho, 'verLink')"
                          />
                        </v-list-item-content>
                        <v-list-item-action v-if="isExcluirAnexos">
                          <v-list-item-action-text
                            @click="deletarArquivoUpado(anexoSC.caminho , anexoSC.idVt, 'sc')"
                          >
                            <v-icon color="segunda" left>delete_forever</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action class="pl-3">
                          <v-list-item-action-text
                            @click="abrirArquivo(anexoSC.caminho, 'verLink')"
                          >
                            <v-icon color="segunda" left>open_in_new</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                        <v-list-item-action>
                          <v-list-item-action-text
                            @click="downloadArquivo(anexoSC.caminho, anexoSC.tipo, anexoSC.nomeArquivo, 'download')"
                          >
                            <v-icon color="segunda" left>downloading</v-icon>
                          </v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <div v-if="cardClicadoAtual.anexo.length == 0" class="my-0 py-0 mt-n5">
                    <h3
                      class="c-fase-views__titulos pl-1 segunda--text text-uppercase text-subtitle-1 font-weight-black"
                    >Sem arquivos</h3>
                    <p class="pl-1 segunda--text">clique em anexar arquivos</p>
                  </div>
                </v-card>
              </v-col>
              <v-col
                v-if="opFases.checkBoxOpcao"
                class="c-views-checkbox py-0"
                cols="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <v-list flat subheader color="dprimeira">
                  <v-list-item-group
                    v-model="opCheckboxs"
                    color="segunda"
                    multiple
                    active-class
                    @change="atAtividades($event, opFases.checkBoxTitulo, 4, 'opcoesFase')"
                  >
                    <v-text-field
                      color="segunda"
                      solo
                      flat
                      dense
                      :value="opFases.checkBoxTitulo"
                      :hint="opFases.checkBoxObs"
                      persistent-hint
                      readonly
                      class="c-fase-views__titulos"
                    ></v-text-field>
                    <v-subheader class="mt-0 mb-n3 mt-n3">
                      <v-chip
                        color="segunda"
                        text-color="white"
                        x-small
                      >{{opFases.checkBoxIsObrigatorio ? 'Opção obrigatória': 'Opção opcional'}}</v-chip>
                    </v-subheader>

                    <v-list-item
                      v-for="(ckeckbox, idChecbox) in opFases.checkboxCampos"
                      :key="idChecbox"
                    >
                      <template v-slot:default="{ active, }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" color="segunda"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title v-text="ckeckbox" />
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col
                v-if="opFases.radioOpcao"
                class="c-views-checkbox py-0"
                cols="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <v-list flat subheader color="dprimeira">
                  <v-list-item-group
                    v-model="opRadios"
                    color="segunda"
                    active-class
                    id="c-views-radio"
                    @change="atAtividades($event, opFases.radioTitulo, 5, 'opcoesFase')"
                  >
                    <v-text-field
                      color="segunda"
                      solo
                      flat
                      dense
                      :value="opFases.radioTitulo"
                      :hint="opFases.radioObs"
                      persistent-hint
                      readonly
                      class="c-fase-views__titulos"
                    ></v-text-field>
                    <v-subheader class="mt-0 mb-n3 mt-n4">
                      <v-chip
                        color="segunda"
                        text-color="white"
                        x-small
                      >{{opFases.radioObrigatorio ? 'Opção obrigatória': 'Opção opcional'}}</v-chip>
                    </v-subheader>

                    <v-list-item v-for="(radio, idRadio) in opFases.radioCampos" :key="idRadio">
                      <template v-slot:default="{ active, }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active" color="segunda"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title v-text="radio" />
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col
                v-if="opFases.textoCurtoOpcao"
                class="c-views-checkbox py-0"
                cols="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                <v-text-field
                  color="segunda"
                  solo
                  flat
                  dense
                  :value="opFases.textoCurtoTitulo"
                  :hint="opFases.textoCurtoObs"
                  persistent-hint
                  readonly
                  class="c-fase-views__titulos c-fases-texto_curto"
                ></v-text-field>
                <v-textarea
                  clearable
                  filled
                  dense
                  placeholder="Digite seu texto aqui"
                  auto-grow
                  v-model="cardClicadoAtual.opcoesFase[idFaseNoCard].textoCurto"
                  @change="atAtividades( $event, opFases.textoCurtoTitulo, 6, 'opcoesFase')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-col justify="start" id="c-comentario" class="c-comentario pt-6">
              <h3
                class="c-fase-views__titulos segunda--text text-subtitle-1 font-weight-black pb-1"
              >Atividades</h3>
              <adicionarComentario @receberComentario="publicarComentario($event)" />
              <div></div>
              <listaAtividades @abrirArquivoAtividade="abrirArquivoAtividade($event)"
              @downloadArquivoAtividade="downloadArquivoAtividade($event)"
              :atv="cardClicadoAtual.atividades" :dialogoEventAtv="eventDialogo"></listaAtividades>
            </v-col>
          </v-sheet>
        </v-col>
        <!-- Terceira Coluna -->
        <v-col
          cols="2"
          lg="2"
          md="2"
          sm="2"
          xs="2"
          class="mt-0 pt-0 px-2 c-views-col c-terceira-coluna"
        >
          <v-text-field
            background-color="dprimeira"
            class="c-mover-fase__titulo"
            solo
            flat
            value="Mover para fases"
            
            hide-details
          ></v-text-field>
          <v-btn
            @click="fecharCards()"
            absolute
            right
            top
            fab
            x-small
            color="sexta"
            elevation="1"
            class="mt-6"
          >
            <v-icon color="segunda">close</v-icon>
          </v-btn>
          <v-sheet color="dprimeira" elevation="0" width="100%" class="c-viewsDataCard-item">
            <v-row justify="center">
              <v-col class="d-flex justify-center flex-column">
                <v-list
                  id="c-lista-fases__mover"
                  class="c-lista-fases__mover px-0"
                  color="dprimeira"
                >
                  <v-list-item-group
                    v-model="getFasesPermitidas"
                    color="segunda"
                    multiple
                    active-class
                  >
                    <v-list-item
                      v-for="(moverParaFase, colFMover) in getTodasFasesBd"
                      :key="colFMover"
                      class="c-fases-permitidas__item"
                      :class="[{ocultarMoverCol: !isFaseNegada(moverParaFase.posicaoFase), isLinkInativoRadio: isRadios, isLinkInativoCheckBox: isCheckBoxs, isFaseAnterior: isFaseAnterior(moverParaFase.id)}]"
                    >
                      <template v-slot:default="{ active, }">
                        <v-list-item-content
                          @click="linkMoverCard(moverParaFase.id, moverParaFase.posicaoFase)"
                          v-if="isFaseNegada(moverParaFase.posicaoFase)"
                          class="pa-0 ma-0"
                        >
                          <v-list-item-title class="pa-0 ma-0 c-fases-permitidas__nome" v-text="moverParaFase.nomeFase" />
                        </v-list-item-content>

                        <v-list-item-action
                          @click="linkMoverCard(moverParaFase.id, moverParaFase.posicaoFase)"
                          class="pr-1"
                          v-if="isIconAvancar(moverParaFase.posicaoFase) && isFaseNegada(moverParaFase.posicaoFase)"
                        >
                          <v-icon color="segunda" :input-value="active">keyboard_tab</v-icon>
                        </v-list-item-action>

                        <v-list-item-action
                          @click="linkMoverCard(moverParaFase.id, moverParaFase.posicaoFase)"
                          v-if="isIconVoltar(moverParaFase.posicaoFase) && isFaseNegada(moverParaFase.posicaoFase)"
                          class="pr-0"
                        >
                          <v-icon color="segunda" :input-value="active">keyboard_return</v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-btn
                  small
                  elevation="0"
                  rounded
                  @click="configMoverCard()"
                  class="segunda--text c-btn-confg-mover"
                >
                  <v-icon color="segunda" class="mr-1">swipe</v-icon>Configurar mover cards
                </v-btn>
                <modalCadastroPregao
                  v-if="opFases.integracaoSisPregao"
                  :sistemPregao="opFases.integracao"
                  :passIdPregao="integracaoPregao"
                >
                  <template v-slot:btnDialogo="slotProps">
                    <v-btn
                      v-bind="slotProps.abrirDialogo.atributos"
                      v-on="slotProps.abrirDialogo.evento"
                      small
                      elevation="0"
                      rounded
                      class="segunda--text c-btn-confg-mover mt-3"
                      outlined
                      @click="abriuIntegracao()"
                    >
                      <v-icon color="segunda" class="mr-1">add_chart</v-icon>GERAR PREGÃO
                    </v-btn>
                  </template>
                </modalCadastroPregao>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento";
import adicionarComentario from "./adicionarComentario";
import oportunidade from "./oportunidade";
import listaAtividades from "./listaAtividades";
import listaComentario from "./listaComentario";
import historico from "./historico";
import modalCadastroPregao from "../integracao/modalCadastroPregao";

export default {
  name: "TelaViewsDataCard",
  components: {
    adicionarComentario,
    oportunidade,
    listaAtividades,
    listaComentario,
    historico,
    modalCadastroPregao
  },
  props: {
    eventDialogo: Boolean,
    posicaoCardViews: Number,
    pf: Number,
    idFaseNoCard: Number,
    dadosUser: Object,
    cardClicado: Object,
    opFases: Object,
    nomeFase: String,
    getTodasFasesBd: Array
  },
  data: function() {
    return {
      opCheckboxs: [],
      isCheckBoxs: false,
      opRadios: [],
      isRadios: false,
      getFasesPermitidas: [],
      addComentario: "",
      publicarNovoTexto: "",
      componenteAlterado: false,
      itemModificado: "",
      viewsSelecFile: [1],
      listaHistorico: [1],
      dataVencimentoCard: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 16),

      dateFormattedCard: this.formatDateCard(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 16)
      ),

      tempoRestanteCard: "",
      cardClicadoAtual: Object.assign({}, this.cardClicado),
      estadoAnterior: "",
      tabCol1: null,
      filesViews: [],
      enviarFileIcon: "",
      isExcluirAnexos: false,
      alturaComentario: "8",
      salvarComentario: "",
      opCardDaFase: {
        anexos: {
          anexos: ["vazio"]
        }
      },
      integracaoPregao: ""
    };
  },
  computed: {
    isFaseAnterior() {
      return idFaseVoltarLink => {
        if (idFaseVoltarLink < this.idFaseNoCard) {
          return true;
        } else {
          return false;
        }
      };
    },
    isLinkInativoCheckBox() {
      if (this.opFases.checkBoxOpcao) {
        if (this.opCheckboxs.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isLinkInativoRadio() {
      if (this.opFases.radioOpcao) {
        if (
          Array.isArray(
            this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].radio
          ) ||
          this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].radio ===
            undefined
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isFaseNegada() {
      return isMoverPosicao => {
        let isEncontrou = false;

        for (let fasesParaExibir of this.opFases.fasesPermitidas) {

          if (isMoverPosicao === fasesParaExibir) {
            if (isMoverPosicao !== this.pf) {
              isEncontrou = true;
            }
          }
        }
        if (isEncontrou) {
          return true;
        } else {
          return false;
        }
      };
    },
    isIconAvancar() {
      return idFaseAvanc => {
        if (idFaseAvanc > this.pf) {
          return true;
        } else {
          return false;
        }
      };
    },
    isIconVoltar() {
      return idFaseVoltar => {
        if (idFaseVoltar < this.pf) {
          return true;
        } else {
          return false;
        }
      };
    },
    apenasAnexosDaFase() {
      return todosAnexosAllFases => {
        return todosAnexosAllFases.filter((el, idx) => {
          el.idVt = idx;
          return el.idFase === `${this.cardClicadoAtual.fase}`;
        });
      };
    },
    formatDataAnexo() {
      return dataAnexo => {
        if ((dataAnexo === null) | (dataAnexo === undefined)) {
          return this.formatDateCard(
            new Date(this.cardClicadoAtual.created_at)
              .toISOString()
              .substring(0, 16)
          );
        } else {
          return this.formatDateCard(new Date(new Date(dataAnexo) - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 16),
          );
        }
      };
    },
    listaAnexosAllFases() {
      let soAnexosNaFase = [];
      return opNasFases => {
        opNasFases.filter(op => {
          if (op.anexos.anexos[0] !== "vazio") {
            soAnexosNaFase.splice(0, 1, {
              dataHora: op.anexos.dataHora,
              nome: op.anexos.nome,
              anexos: [...op.anexos.anexos]
            });
          }
        });
        return soAnexosNaFase;
      };
    },
    calcDiasRestanteLt() {
      return dataRecebida => {
        let dataHoje = new Date().toISOString();

        let instanciaDataAgora = this.$moment(dataHoje);
        let instanciaDataRecebida = this.$moment(
          this.parseDateCard(dataRecebida)
        );

        if (instanciaDataAgora.isSame(instanciaDataRecebida, "day")) {
          this.tempoRestanteCard = "Vence hoje!";
          return "Vence hoje!";
        } else if (!instanciaDataAgora.isBefore(instanciaDataRecebida)) {
          this.tempoRestanteCard = `Vencida ${instanciaDataRecebida.from(
            instanciaDataAgora
          )}`;
          return `Vencida ${instanciaDataRecebida.from(instanciaDataAgora)}`;
        } else {
          this.tempoRestanteCard = `Vence ${instanciaDataRecebida.from(
            instanciaDataAgora
          )}`;
          return `Vence ${instanciaDataRecebida.from(instanciaDataAgora)}`;
        }
      };
    }
  },
  watch: {
    opRadios(val) {
      if (
        this.opFases.filtrosFase.campo === "radio" ||
        this.opFases.filtrosFase.campo === "ambos"
      ) {
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].radio = val;
        if (val === undefined || Array.isArray(val)) {
          this.isRadios = true;
        } else {
          this.isRadios = false;
        }
      }
    },
    opCheckboxs(val) {
      if (
        this.opFases.filtrosFase.campo === "checkBox" ||
        this.opFases.filtrosFase.campo === "ambos"
      ) {
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].checkbox = val;
        if (val.length === 0) {
          this.isCheckBoxs = true;
        } else {
          this.isCheckBoxs = false;
        }
      }
    },
    componenteAlterado(val) {
      if (val) {
        this.atDadosCard();
      }
    },
    filesViews(filesNovo) {
      this.filesViews.length > 0
        ? (this.enviarFileIcon = "file_upload")
        : (this.enviarFileIcon = "");
    },
    dataVencimentoCard(val) {

      this.cardClicadoAtual.vencimento = val
      this.cardClicadoAtual.tempo = this.tempoRestanteCard;

      this.atAtividades(this.formatDateCard(val), "Vencimento", 9, "vencimento", 'vencimento', true);
    },
    eventDialogo: {
      handler(acaoNova, acaoAntiga) {
        
        this.cardClicadoAtual = Object.assign({}, this.cardClicado);
        this.opCheckboxs = this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].checkbox;
        this.opRadios = this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].radio;

        this.dateFormattedCard = this.formatDataDoBanco(this.cardClicadoAtual.vencimento)
        this.cardClicadoAtual.vencimento = this.dateFormattedCard

        this.dateFormattedCard = this.formatDataDoBanco(this.cardClicadoAtual.vencimento);
        this.cardClicadoAtual.vencimento = this.dateFormattedCard;
        this.cardClicadoAtual.tempo = this.tempoRestanteCard;

      if (acaoNova) {
        this.getFasesPermitidas = [...this.opFases.fasesPermitidas];
      } else {
        this.getFasesPermitidas = [];
      }
      const dataInicalCard = {
          titulo: this.cardClicadoAtual.titulo,
          vencimento: this.cardClicadoAtual.vencimento,
          oportunidade: this.cardClicadoAtual.oportunidade,
          fase: this.cardClicadoAtual.fase,
          idCard: this.cardClicadoAtual.id,
      }
      this.salvarEstadoNaAbertura(acaoNova, dataInicalCard)
      
      },
      immediate: true
    },
  },
  methods: {
    salvarEstadoNaAbertura(isAbraiu, dataCard) {
      const key = "dataOpen";
      if(isAbraiu){
        const data = {
          titulo: dataCard.titulo,
          vencimento: dataCard.vencimento,
          oportunidade: dataCard.oportunidade,
          fase: dataCard.fase,
          idCard: dataCard.idCard,
        };
        localStorage.setItem(key, JSON.stringify(data));
      }else{
        localStorage.removeItem(key);
        const tt = JSON.parse(localStorage.getItem(key));
      }
    },
    downloadArquivoAtividade(ev){
      this.downloadArquivo(ev.caminho, ev.tipo, ev.nome, ev.action)
    },
    abrirArquivoAtividade(ev){
      this.abrirArquivo(ev.caminho, ev.action)
    },
    formatDataDoBanco(dataFinal) {
      if (!dataFinal) return null;
      if (dataFinal.indexOf("/", 3) > -1) {
        const [dayAtual, monthAtual, yearAtual] = dataFinal.split("/");
            return `${yearAtual}-${monthAtual}-${dayAtual}T00:00`;
          }else{
            return dataFinal
          }
      },

    abriuIntegracao() {
      this.integracaoPregao = this.cardClicadoAtual.titulo;
    },
    linkMoverCard(idFaseMover, pFaseMover) {
      let dataPMoverCard = {};
      dataPMoverCard.pFaseS = this.pf;
      dataPMoverCard.idFaseS = this.idFaseNoCard;
      dataPMoverCard.idFaseD = idFaseMover;
      dataPMoverCard.pFaseD = pFaseMover;
      dataPMoverCard.idCard = this.cardClicadoAtual.id;
      dataPMoverCard.pCard = this.posicaoCardViews;
      dataPMoverCard.data = this.cardClicadoAtual;
      this.$emit("moverCardViaLink", dataPMoverCard);
      this.fecharCards();
    },
    configMoverCard() {
      let atMoverCard = {};

      atMoverCard.NomeFaseAtual = this.nomeFase;
      atMoverCard.posicaoFaseAtual = this.pf;
      atMoverCard.idFaseAtual = this.idFaseNoCard;
      atMoverCard.OpFaseAtual = this.opFases;
      atMoverCard.todasFases = this.getTodasFasesBd;

      this.$emit("abrirMoverCard", atMoverCard);
    },
    apenasString(str) {
      return str.replace(/(<([^>]+)>)/gi, "").replace(/&nbsp/gi, "");
    },
    atTextOpt(event) {
      this.cardClicadoAtual.oportunidade = event;
      this.atAtividades(event, "Oportunidade", 7, "oportunidade", 'oportunidade', true);
    },
    changeTitulo(event) {
      this.cardClicadoAtual.titulo = event;
      this.atAtividades(event, "Número Pregão", 8, "titulo", 'titulo', true);
    },
    atAtividadesAnexos(anexosNovos, idTipoCampo, isExcluir) {
      let valorAnterior = this.getUltimoEstado(
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].historico[
          idTipoCampo
        ],
        anexosNovos,
        idTipoCampo
      );
      this.cardClicadoAtual.atividades.unshift({
        dataHora: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString(),
        autor: `${this.dadosUser.name} ${
          isExcluir ? "deletou" : "adicionou"
        } arquivos em ${this.opFases.anexoNome}`,
        comentario: "",
        isComentario: false,
        estado: {
          anterior:
            typeof valorAnterior === "string"
              ? false
              : this.identificarCampos(valorAnterior, idTipoCampo),
          atual: this.identificarCampos(anexosNovos, idTipoCampo)
        },
        isEstado: false,
        mudancaFase: "",
        isMudancaFase: false,
        anexos: this.identificarCampos(anexosNovos, idTipoCampo),
        isAnexos: true,
        isExclusaoAnexo: isExcluir
      });
      this.itemModificado = "opcoesFase";
      this.componenteAlterado = true;
    },
    getUltimoEstado(valorHistorico, valorEvent, idTipoCampo) {

      if ((valorHistorico === null) | (valorHistorico === undefined)) {
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].historico[
          idTipoCampo
        ] = valorEvent;
        return "' '";
      } else {
        let historico = this.cardClicadoAtual.opcoesFase[this.idFaseNoCard]
          .historico[idTipoCampo];
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].historico[
          idTipoCampo
        ] = valorEvent;
        return historico;
      }
    },
    identificarCampos(id, campo) {
      if (campo === 5) {
        return this.opFases.radioCampos[id];
      } else if (campo == 4) {
        let camposMarcados = [];
        id.filter(el => {
          camposMarcados.push(this.opFases.checkboxCampos[el]);
        });
        return camposMarcados;
      } else if (campo == 3) {
        let todosAnexos = [];
        id.filter(el => {
          todosAnexos.push({
            caminho: el.caminho,
            coluna: el.coluna,
            icone: el.icone,
            nomeArquivo: el.nomeArquivo,
            size: el.size,
            tipo: el.tipo,
            inseridoEm: el.horaData,
            hora: el.hora == undefined ? "Hora: Não Definida": el.hora,
            nomeCampo: el.nomeCampo,
            nomeFase: this.opFases.nomeFase
          });
        });
        return todosAnexos;
      } else {
        return "Campo Vazio";
      }
    },
    atAtividades(event, nomeDoCampo, idTipoCampo, itemModificado, estadoInical, isCamposPrincipais) {

      let valorAnterior = this.getUltimoEstado(
        this.cardClicadoAtual.opcoesFase[this.idFaseNoCard].historico[
          idTipoCampo
        ],
        event,
        idTipoCampo
      );

      if(isCamposPrincipais){
        if(valorAnterior == "' '"){
        const estadoDaAbertura = JSON.parse(localStorage.getItem("dataOpen"))[estadoInical];
          if(estadoInical == 'vencimento'){
            valorAnterior = this.formatDateCard(estadoDaAbertura)
          }else{
            valorAnterior = estadoDaAbertura
          }
      }
    }

      this.cardClicadoAtual.atividades.unshift({
        dataHora: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString(),
        autor: `${this.dadosUser.name} atualizou ${nomeDoCampo}`,
        comentario: "",
        isComentario: false,
        estado: {
          anterior:
            typeof valorAnterior === "string"
              ? this.apenasString(valorAnterior)
              : this.identificarCampos(valorAnterior, idTipoCampo),
          atual:
            typeof event === "string"
              ? this.apenasString(event)
              : this.identificarCampos(event, idTipoCampo)
        },
        isEstado: true,
        mudancaFase: "",
        isMudancaFase: false,
        anexos: [],
        isAnexos: false
      });

      this.itemModificado = itemModificado;
      this.componenteAlterado = true;
    },
    atOpFaseNoCard(event, acao, nomeDoCampo) {
      this.itemModificado = "opcoesFase";
      this.componenteAlterado = true;
    },
    fecharCards() {
      this.$emit("fecharCard");
    },
    atNovosDadosCard(novosDadosCard) {
      this.$emit("atDadosNovosCard", novosDadosCard);
    },
    atAtividadesCard() {
      /* Para atualizar no banco e no sistema front end */
      let atividades = {};
      atividades.pfase = this.pf;
      atividades.pCardFase = this.posicaoCardViews;
      atividades.id = this.cardClicadoAtual.id;
      atividades.item = this.itemModificado;
      atividades[`${this.itemModificado}`] = this.cardClicadoAtual[
        `${this.itemModificado}`
      ];
      this.itemModificado = "";
      axios
        .post(`${urlApi}/card/atDadosAtividades`, atividades)
        .then(res => {
          this.atNovosDadosCard(atividades);
         /*  this.$toasted.global.defaultSuccess({ msg: "Atividade Alterada" }); */
        })
        .catch(showError);
    },
    atDadosCard() {
      let dadosAlterados = {};
      dadosAlterados.pfase = this.pf;
      dadosAlterados.pCardFase = this.posicaoCardViews;
      dadosAlterados.id = this.cardClicadoAtual.id;
      dadosAlterados.item = this.itemModificado;
      dadosAlterados[`${this.itemModificado}`] = this.cardClicadoAtual[
        `${this.itemModificado}`
      ];
      if (dadosAlterados.item === "vencimento") {
        dadosAlterados.vencimento = this.formatDateCardParaAt(
          dadosAlterados.vencimento
        );
      }
      this.componenteAlterado = false;
      this.itemModificado = "";
      axios
        .post(`${urlApi}/card/atDadosCard`, dadosAlterados)
        .then(res => {
          this.atNovosDadosCard(dadosAlterados);

          this.itemModificado = "atividades";
          this.atAtividadesCard();

          /* this.$toasted.global.defaultSuccess(); */
        })
        .catch(showError);
    },
    publicarComentario(dadosComentario) {
      this.addComentario = dadosComentario;
      this.cardClicadoAtual.atividades.unshift({
        dataHora: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString(),
        autor: `${this.dadosUser.name} comentou`,
        comentario: this.addComentario,
        isComentario: true,
        fase: this.nomeFase,
        estado: {
          anterior: "",
          atual: ""
        },
        isEstado: false,
        mudancaFase: "",
        isMudancaFase: false
      });
      this.addComentario = "";
      this.atOpFaseNoCard();
    },
    atualizarNovosAnexos(dadosAnexo) {
      this.$emit("atualizarAnexos", dadosAnexo);
    },
    fileUpload(coluna, nomeCampo, idFase) {
      let dadosNovosAnexos = {};
      dadosNovosAnexos.horaData = this.formatDateCardAnexo(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 16))
      dadosNovosAnexos.pCardFase = this.posicaoCardViews;
      dadosNovosAnexos.pfase = this.pf;
      dadosNovosAnexos.idFase = `${idFase}`;
      let formData = new FormData();
      this.filesViews.filter((ele, index, array) => {
        formData.append(ele.name, ele);
      });
      formData.append("coluna", coluna);
      formData.append("nomeCampo", nomeCampo);
      formData.append("horaData", dadosNovosAnexos.horaData);
      formData.append("atAnexo", true);
      formData.append("idCard", this.cardClicadoAtual.id);
      formData.append("idFase", `${idFase}`);
      /* Pegando dados dos anexos já inseridos no banco */
      formData.append(
        "anexosNoBd",
        JSON.stringify(this.cardClicadoAtual.anexo)
      );
      axios
        .post(`${urlApi}/UpArquivo`, formData)
        .then(res => {
          dadosNovosAnexos.novosAnexos = res.data[0].anexo;
          this.atualizarNovosAnexos(dadosNovosAnexos);
          this.cardClicadoAtual.anexo = res.data[0].anexo;
          this.atAtividadesAnexos(res.data[0].anexo, 3, false);
          this.filesViews = [];
          /* this.$toasted.global.defaultSuccess(); */
        })
        .catch(showError);
    },
    deletarArquivoUpado(caminho, idDelete, coluna) {
      let todosAnexosAtual = [...this.cardClicadoAtual.anexo];
      todosAnexosAtual.splice(idDelete, 1);
      this.cardClicadoAtual.anexo = [...todosAnexosAtual];

      let atAnexos = {};
      atAnexos.novosAnexos = this.cardClicadoAtual.anexo;
      atAnexos.pCardFase = this.posicaoCardViews;
      atAnexos.pfase = this.pf;

      let fileDelet = {};
      fileDelet.anexoAtualizado = JSON.stringify(this.cardClicadoAtual.anexo);
      this.atAtividadesAnexos(this.cardClicadoAtual.anexo, 3, true);
      fileDelet.caminho = caminho;
      fileDelet.CardId = this.cardClicadoAtual.id;

      axios
        .post(`${urlApi}/deletarFile`, fileDelet)
        .then(res => {
          this.atualizarNovosAnexos(atAnexos);
          /* this.$toasted.global.defaultSuccess(res.data); */
        })
        .catch(showError);
    },
    downloadArquivo(caminho, tipo, nome, action) {
      let urli = `${urlApi}/card/path?caminho=${caminho}&key=${this.dadosUser.token}&action=${action}`;
      axios({
        url: urli,
        method: "GET",
        responseType: "blob"
      })
        .then(res => {
          const arquivo = new File([res.data], "fileNameGoesHere", {
            type: tipo
          });
          const objetoURL = window.URL.createObjectURL(arquivo);
          const linkDowload = document.createElement("a");
          linkDowload.href = objetoURL;
          linkDowload.type = tipo;
          linkDowload.setAttribute("download", nome);
          document.body.appendChild(linkDowload);
          linkDowload.click();
          linkDowload.remove();
        })
        .catch(showError);
    },
    abrirArquivo(caminho, action) {
      let urli = `${urlApi}/card/path?caminho=${caminho}&key=${this.dadosUser.token}&action=${action}`;
      axios
        .get(urli)
        .then(res => {
          window.open(urli);
        })
        .catch(showError);
    },
    formatDateCardAnexo(dataVencimento, campo) {
      if (!dataVencimento) return null;
      const [year, month, day] = dataVencimento.split("-");
      if(day.length > 3){
        return `${day.substr(0, 2)}/${month}/${year} às: ${day.substr(3, 5)}`;
      }else{
        return `${day.substr(0, 2)}/${month}/${year} Hora: Não Definida`;
      }
    },
    formatDateCard(dataVencimento) {
      if (!dataVencimento) return null;
      const [year, month, day] = dataVencimento.split("-");
      if(day.length > 3){
        return `${day.substr(0, 2)}/${month}/${year} Hora: ${day.substr(3, 5)}`;
      }else{
        return `${day.substr(0, 2)}/${month}/${year} Hora: Não Definida`;
      }
    },
    formatDateCardParaAt(dataVencimento) {
      if (!dataVencimento) return null;
      return dataVencimento;
    },
    parseDateCard(dataVencimento) {
      // Caso a data seja no formato com / converte para o formato - tornando possivel o calculo de dias com a lib moment
      if (!dataVencimento) return null;
      if (dataVencimento.indexOf("/", 3) > -1) {
        const [day, month, year] = dataVencimento.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        return dataVencimento;
      }
    }
  },
};
</script>

<style>
.tabColFormInicial .v-item-group {
  background: #f9f8f8 !important;
}
.tabColFormInicial a {
  letter-spacing: 0.5px;
  font-weight: 700;
}
.c-content-tab__col1 h3 {
  font-weight: 800;
  color: #363494;
  margin-bottom: 10px;
}
.c-content-tab__col1 p {
  padding-left: 5px;
  font-weight: 900;
}
.c-content-tab__col1 {
  margin: 10px 5px;
  border-radius: 10px;
}
.c-viewsDataCard-item {
  position: sticky !important;
  overflow-y: auto;
  height: 1vh;
  min-height: 83vh;
  max-height: 100%;
  overflow-x: hidden;
}
.c-viewsDataCard-item::-webkit-scrollbar {
  width: 8px;
}
.c-viewsDataCard-item::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.c-viewsDataCard-item:-webkit-scrollbar-thumb:active {
  background: #ffffff;
}
.c-viewsDataCard-item::-webkit-scrollbar-thumb {
  background: #f9f8f8;
  border: 0px none #f9f8f8;
  border-radius: 50px;
}
.c-viewsDataCard-item::-webkit-scrollbar-track {
  background: #f8f9f8;
  border: 0px none #f9f8f8;
  border-radius: 50px;
}
.c-viewsDataCard-item::-webkit-scrollbar-track:hover {
  background: #dddddd;
}
.c-viewsDataCard-item::-webkit-scrollbar-track:active {
  background: #f9f8f8;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-thumb:hover {
  background: #4d00a5;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-thumb:active {
  background: #4d00a5;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-thumb {
  background: #bcbbbb;
  border: 0px none #f9f8f8;
  border-radius: 50px;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-track {
  background: #dddddd;
  border: 0px none #f9f8f8;
  border-radius: 50px;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-track:hover {
  background: #f9f8f8;
}
.c-viewsDataCard-item:hover::-webkit-scrollbar-track:active {
  background: #f9f8f8;
}
.c-fase-views__titulo input {
  color: #363494 !important;
  font-weight: 700;
  border-radius: 5px;
  background: #d4d3de;
  padding-left: 16px;
  border-radius: 5px px;
}
.c-fase-views__titulo {
  border: solid 1px #363494;
}
.c-fase-views__titulo__sub {
  color: #6b6b6b;
}
.c-fase-views__titulos input {
  color: #363494 !important;
  font-weight: 900;
  text-transform: uppercase;
}
.c-fase-views__titulos .v-input__slot {
  background: #dddddd !important;
}
.c-primeira-coluna,
.c-segunda-coluna {
  border-right: solid 1px #363494 !important;
}
/* Controla a views dos formulários */
#c-views-anexo-sc .pc,
#c-views-anexo-sc .fi,
#c-views-anexo-sc .true {
  display: none;
}
.c-ver-anexos .sc {
  display: flex;
}
.u-hover input {
  transition: ease 500ms;
}
.u-hover input:hover {
  padding-left: 10px;
  border-radius: 5px;
  transition: ease 500ms;
  box-shadow: 1px 1px 8px 5px #0000002b;
}
.c-atividades-ct {
  background: rgb(54 52 148 / 12%);
  border-radius: 10px;
}
.c-atividades-acao {
  white-space: normal;
}
.c-atividades-data {
  box-shadow: -1px 2px 2px 1px rgb(0 0 0 / 8%);
}
.c-atividade-comentario {
  line-height: 1.2rem !important;
  border-radius: 25px;
  white-space: normal;
}
.c-atividades-views .v-expansion-panel-header {
  min-height: 35px;
  height: 35px;
}
.c-atividades-anexos-sbtitle .v-chip {
  margin: 2px;
}
.ocultarMoverCol {
  pointer-events: none;
  display: none;
}
.c-lista-fases__mover .c-fases-permitidas__item {
  margin-bottom: 10px;
}
.isLinkInativoCheckBox,
.isLinkInativoRadio {
  opacity: 0.8;
  pointer-events: none;
}
.isLinkInativoCheckBox:after,
.isLinkInativoRadio:after {
  content: "Opções obrigatórias";
  display: block;
  font-size: 11px;
  font-weight: 800;
  color: #ff3131;
  line-height: 11px;
  position: absolute;
  top: calc(71%);
  right: calc(10%);
}
.isFaseAnterior {
  pointer-events: auto !important;
  opacity: 1 !important;
}
.isFaseAnterior:after {
  display: none !important;
}
.c-btn-confg-mover {
  height: 40px !important;
}
#c-lista-fases__mover .c-fases-permitidas__item {
  background: #dddddd99;
  color: #363494;
}
.c-mover-fase__titulo input {
  color: #727272 !important;
  text-transform: uppercase;
  font-size: 14px;
  background: #eae9e9;
  margin-left: -12px;
  padding-left: 8px;
  border-radius: 5px;
}
.c-fases-texto_curto {
    font-size: 13px;
}
.c-fases-permitidas__nome {
    font-size: 13px;
    font-weight: 900;
}
#c-datetime-local input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    width: initial!Important;
    font-size: initial!important;
    cursor: pointer;
    filter: initial!important;
}
</style>