<template>
  <v-col
    cols="12"
    lg="12"
    md="12"
    sm="12"
    xs="12"
    class="ma-0 pa-0 c-fases-wrapper"
  >
  <v-progress-linear
      absolute
      color="segunda"
      indeterminate
      height="4"
      :active="looad"
    ></v-progress-linear>
    <v-dialog
      persistent
      class="dialogAddTituloFrase"
      overlay-color="segunda"
      v-model="dialogOpFase"
      width="800"
    >
      <opFase
        :ordenacaoCards="ordenacaoCards"
        :nomeFaseOpcoes="nomeFaseOpcoes"
        :posicaoDaFase="posicaoDaFase"
        :idFaseClicada="idFaseClicada"
        :opFaseClicada="opFaseClicada"
        :isdialogOpFase="dialogOpFase"
        @fecharOpFase="dialogOpFase = false"
        @atulizarOpcoesDaFase="novasOpFase($event)"
        @resetarOpcao="resetarOp($event)"
      ></opFase>
    </v-dialog>

    <v-dialog
      content-class="c-mover-card-dalog"
      overlay-color="segunda"
      v-model="dialogMoverCards"
      width="600"
    >
      <opMoverCards
        :nomeEIdFases="nomeEIdFases"
        :isdialogMoverCards="dialogMoverCards"
        @clouseMoverCards="dialogMoverCards = false"
        @atOpMoverCard="atOpMoverCardBd($event)"
      ></opMoverCards>
    </v-dialog>

    <v-dialog
      class="dialogAddTituloFrase"
      overlay-color="segunda"
      v-model="dialogAddTituloFrase"
      width="500"
    >
      <v-card color="segunda">
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="pt-5 pb-n5">
              <v-text-field
                background-color="primeira"
                solo
                class="c-dados-cards"
                outlined
                color="sexta"
                v-model="dataFases.nome"
                label="Nome da Fase"
                hint="Insira o Nome da Fase"
                clearable
                prepend-inner-icon="power_input"
                v-on:keyup.enter="fecharDialogoAddTituloFrase"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text></v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="sexta--text"
            @click="fecharDialogoAddTituloFrase"
            >SALVAR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      class="dialogAddTituloFrase"
      overlay-color="segunda"
      v-model="novaLicitacao"
      width="800"
    >
      <v-card color="sexta">
        <v-card-title>
          <v-sheet
            elevation="4"
            color="sexta"
            width="100%"
            class="my-2 pa-2"
            rounded
          >
            <h3 class="segunda--text font-weight-regular">
              <v-icon color="segunda" class="pr-2">mdi-briefcase</v-icon>NOVA
              OPORTUNIDADE DE NEGÓCIO
            </h3>
          </v-sheet>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="pt-5 pb-n5">
              <v-text-field
                class="c-dados-cards__nome"
                outlined
                color="segunda"
                @change="inseridoNovoTitulo($event)"
                label="Número do Pregão / Comprador"
                hint="Informar o tipo (presencial ou eletrônico), o número/ano e o comprador"
                persistent-hint
                clearable
                prepend-inner-icon="assignment"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="pt-5 pb-n5">
              <h3 class="mb-3"><v-icon>description</v-icon>Oportunidade</h3>
              <VueEditor
                id="editorFomInicial"
                prepend-inner-icon="description"
                placeholder="Descrição da oportunidade"
                :editorToolbar="customToolbar"
                v-model="dataCards.oportunidade"
              />
            </v-col>
          </v-row>
          <v-row justify="space-around" align-content="center">
            <v-col
              cols="12"
              lg="5"
              md="5"
              sm="4"
              xs="4"
              class="add-info-card__footer"
            >
            <v-text-field
            value=""
             class="c-dados-cards__vencimento"
             color="segunda"
             v-model="dataEhoraCard"
             type="datetime-local"
             label="Vencimento"
             >
             
            </v-text-field>
              <!-- <v-menu
                ref="popupDataVencimento"
                v-model="popupDataVencimento"
                :close-on-content-click="false"
                transition="scale-transition"
                origin="center center"
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="c-dados-cards__vencimento"
                    color="segunda"
                    v-model="dateFormatted"
                    label="Vencimento"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="dataVencimento = parseDate(dateFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="segunda"
                  v-model="dataVencimento"
                  no-title
                  @input="popupDataVencimento = false"
                ></v-date-picker>
              </v-menu> -->
              <p class="calcDiasRestante mt-n3 pb-2">
                {{ resumoCalcDiasRestante(dataEhoraCard) }}
              </p>
            </v-col>
            <v-col
              cols="2"
              lg="1"
              md="1"
              sm="1"
              xs="1"
              class="c-dados-cards__anexo_col"
            >
              <v-file-input
                color="segunda"
                v-model="files"
                multiple
                label="Anexos"
                class="c-dados-cards__anexo"
                hide-input
              ></v-file-input>
              <p>ANEXOS</p>
            </v-col>
            <v-col
              cols="2"
              lg="1"
              md="1"
              sm="1"
              xs="1"
              class="c-dados-cards__permissao"
            >
              <v-dialog v-model="dialogUsuarios" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="3"
                    height="41"
                    width="40"
                    min-width="40"
                    color="segunda"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class
                  >
                    <v-icon right dark>group_add</v-icon>
                  </v-btn>
                  <p>RESPONSÁVEIS</p>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2"
                    >Selecione os usuários</v-card-title
                  >

                  <v-card-text>
                    <v-card class="c-dados-card">
                      <v-list dense>
                        <v-subheader>Usuários cadastrados</v-subheader>
                        <v-list-item-group
                          v-model="userMarcados"
                          color="segunda"
                          multiple
                          active-class
                        >
                          <v-list-item
                            v-for="(user, idUser) in userPermissoes"
                            :key="idUser"
                          >
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>

                              <v-list-item-icon class="pa-2 pt-4">
                                <v-icon large>person_add</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="user.name" />
                                <v-list-item-subtitle v-text="user.email" />
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="segunda" text @click="definirPermissoes()"
                      >Definir</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              cols="2"
              lg="1"
              md="1"
              sm="1"
              xs="1"
              class="c-dados-cards__etiquetas"
            >
              <v-dialog v-model="dialogEtiquetas" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="3"
                    height="41"
                    width="40"
                    min-width="40"
                    color="segunda"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class
                  >
                    <v-icon right dark>bookmarks</v-icon>
                  </v-btn>
                  <p>ETIQUETAS</p>
                </template>
                <v-card>
                  <v-card-title class="text-h5 grey lighten-2"
                    >Adicione Etiquetas</v-card-title
                  >

                  <v-card-text>
                    <v-card-text>Em breve</v-card-text>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="segunda" text @click="dialogEtiquetas = false"
                      >Adicionar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col
              v-if="payloadFilesUpados.length > 0"
              cols="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="c-dados-cards__anexo_col_files"
            >
              <v-card class="c-dados-card">
                <v-list dense>
                  <v-subheader>Arquivos</v-subheader>
                  <v-list-item-group v-model="selecionarFile" color="segunda">
                    <v-list-item
                      v-for="(item, idLista) in payloadFilesUpados"
                      :key="idLista"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icone"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.nomeArquivo"
                          @click="abrirArquivo(item.caminho)"
                        />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text
                          @click="deletarArquivoUpado(item.caminho, idLista)"
                        >
                          <v-icon color="segunda">delete_forever</v-icon>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primeira" class="sexta--text" @click="addNovoCard"
            >SALVAR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="c-views-cards__dialog"
      scrollable
      v-model="dialogoViewsDataCard"
      transition="dialog-bottom-transition"
    >
      <telaViewsDataCard
        @fecharCard="dialogoViewsDataCard = false"
        :eventDialogo="dialogoViewsDataCard"
        :posicaoCardViews="posicaoCardViews"
        :cardClicado="viewsDataCardClicado"
        :idFaseNoCard="viewsDataCardClicado.fase"
        :pf="posicaoFaseViews"
        :dadosUser="dadosUser"
        :opFases="viewsOpFaseClicada"
        :nomeFase="nomeFaseClicada"
        :getTodasFasesBd="getTodasFasesBd"
        @atualizarAnexos="novosAnexos($event)"
        @atDadosNovosCard="atDadosNovosCards($event)"
        @abrirMoverCard="openMoverCard($event)"
        @moverCardViaLink="goCardViaLink($event)"
      ></telaViewsDataCard>
    </v-dialog>

    <div
      v-dragscroll:nochilddrag
      class="d-flex flex-nowrap flex-coluna c-scroll-horizontal"
    >
      <v-sheet
        data-dragscroll
        v-for="(fase, idx) in fases"
        :key="idx"
        rounded="xl"
        color="quarta"
        elevation="0"
        width="280"
        class="c-col__fase__gestor c-titulo-fase ma-2 true-scroll__ativo"
        :class="{ is_fase_backup: fase.opcoes.faseBackup }"
      >
        <v-fab-transition appear>
          <span class="quarta c-titulo-fase__caixa">
            <span class="c-titulo-fase__titulo segunda--text text-truncate">
              {{ fase.nome }}
              <span class="c-titulo-fase__quant">{{
                quantFase(fase.cards)
              }}</span>
              <span v-if="ativarModoEdicao" class="c-titulo-fase__icon">
                <v-icon
                  v-if="fase.opcoes.porMaisAntigo"
                  color="error"
                  right
                  @click="modificarOrdenacaoCards(idx, fase.id)"
                  >swap_vert</v-icon
                >
                <v-icon
                  v-else
                  color="segunda"
                  right
                  @click="modificarOrdenacaoCards(idx, fase.id)"
                  >swap_vert</v-icon
                >
                <v-icon
                  color="segunda"
                  right
                  @click="
                    definirMoverCards(idx, fase.nome, fase.id, fase.opcoes)
                  "
                  >move_up</v-icon
                >
                <v-icon
                  color="segunda"
                  right
                  @click="opFases(fase.nome, idx, fase.id, fase.opcoes)"
                  >settings</v-icon
                >
              </span>
            </span>
          </span>
        </v-fab-transition>

        <Container
          class="card-container c-fases-ct"
          group-name="fasesNutrimix"
          @drag-start="handleDragStart(idx, $event)"
          @drop="handleDrop(idx, $event)"
          @drop-ready="eventoReady(idx, $event)"
          :getChildPayload="getChildPayload"
          :drop-placeholder="{ className: 'placeholder' }"
        >
          <Draggable
            v-for="(card, indexcard) in fase.cards"
            :key="indexcard"
            :tag="{ value: 'div', props: { class: 'bgCardDrop' } }"
            :class="{ 'u-hide': card.pesquisa }"
          >
            <div class="draggable-item">
              <aside
                class="c-card"
                @click="
                  viewsDataCard(card, fase.opcoes, fase.nome, idx, indexcard)
                "
              >
                <p class="c-card-tt pb-2">{{ card.titulo }}</p>
                <p class="mb-3 text-body-2 font-weight-black c-card_atrasado">
                  <v-icon color="#ff9800" small left>pending_actions</v-icon>
                  {{ resumoCalcDiasRestante(card.vencimento) }}
                </p>
                <div class="c-card-resumo_data">
                  <v-icon color="segunda" small class="mr-1">event</v-icon>
                  <p :class="[antesVencimento(card.vencimento)]">
                    {{ dataCardBd(card.vencimento) }}
                  </p>
                </div>
                <p>
                  <v-icon color="segunda" small>attach_file</v-icon>
                  {{ card.anexo.length }}
                </p>
                <span class="c-btn-remover-card">
                  <v-btn
                    v-if="ativarModoEdicao"
                    class="mb-6 mr-n1"
                    @click="removerCard(card.id, indexcard, idx)"
                    absolute
                    right
                    bottom
                    fab
                    small
                    plain
                    elevation="0"
                  >
                    <v-icon color="segunda">delete_forever</v-icon>
                  </v-btn>
                </span>
              </aside>
            </div>
          </Draggable>
        </Container>
      </v-sheet>
    </div>
  </v-col>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento";
import { Container, Draggable } from "vue-smooth-dnd";
import inicialCards from "./inicialCards";
import { dragscroll } from "vue-dragscroll";
import telaViewsDataCard from "./telaViewsDataCard";
import opFase from "./opFase";
import opMoverCards from "./opMoverCards";
import { VueEditor } from "vue2-editor";
export default {
  name: "fases",
  props: {
    dadosUser: Object,
  },
  directives: {
    dragscroll: dragscroll,
  },
  components: {
    Container,
    Draggable,
    telaViewsDataCard,
    opFase,
    opMoverCards,
    VueEditor,
  },
  data: function () {
    return {
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
        ["bold", "italic", "underline", "strike", "link"],
        ["blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // sobrescrito/subscrito
        [{ indent: "-1" }, { indent: "+1" }], // recuo/recuo

        [{ color: [] }, { background: [] }], // dropdown com padrões
        ["clean"],
      ],
      dialogMoverCards: false,
      dialogOpFase: false,
      dialogUsuarios: false,
      dialogEtiquetas: false,
      files: [],
      dataVencimento: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10),

      dateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 10)
      ),

      dataVencimentoTeste: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substring(0, 10),

      dataEhoraCard: this.formatData(new Date()),

      popupDataVencimento: false,
      dialogAddTituloFrase: false,
      novaLicitacao: false,
      fases: [],
      dataFases: {},
      dataCards: {},
      draggingCard: {
        idFase: "",
        id: -1,
        data: {},
      },
      cardFaseAlterada: {},
      selecionarFile: 1,
      userMarcados: [],
      emailComPermissoes: [],
      payloadFilesUpados: [],
      userPermissoes: [],
      dialogoViewsDataCard: false,
      viewsDataCardClicado: {},
      idFaseClicada: Number,
      posicaoDaFase: Number,
      posicaoFaseViews: Number,
      posicaoCardViews: Number,
      nomeFaseOpcoes: "",
      opFaseClicada: {},
      viewsOpFaseClicada: {},
      nomeFaseClicada: "",
      corVencimento: "",
      filtroFase: false,
      isMesmoCt: false,
      removerEAdd: {},
      ordenacaoCards: true,
      isPasso: true,
      quantFiltros: 0,
      nomeEIdFases: {},
      msgNaoAutorizado: "",
      getTodasFasesBd: [],
      ativarModoEdicao: false,
      bloquearScroll: false,
      fasesComCardsFiltrados: [],
      looad: false
    };
  },

  watch: {
    dialogUsuarios(val) {
      if (val) {
        this.emailComPermissoes = [];
        this.getUserPermissoes();
      }
    },
    dataVencimento(val) {
      this.dateFormatted = this.formatDate(this.dataVencimento);
      this.dataCards.vencimento = this.parseDate(this.dateFormatted);
    },
    dataEhoraCard(novaData) {
      this.dataCards.vencimento = novaData
    },
    files(filesNovos, FilesAntigos) {
      if (filesNovos != FilesAntigos) {
        this.fileUpload("fi", "Formulário Inicial");
      }
    },

    filtrarCardsDaFase(cardsFases, identificacaoFase) {
      let cardsDaFaseFiltrados = [];
      if (cardsFases.length !== 0) {
        cardsFases.filter((card, index, array) => {
          if (card.fase == identificacaoFase) {
            cardsDaFaseFiltrados.push(card);
          }
          return cardsDaFaseFiltrados;
        });
      }
      return cardsDaFaseFiltrados;
    },
  },
  // definindo estilos
  computed: {
      dataCardBd(){
        return (dataCard) => {
          if (!dataCard) return null;
          if (dataCard.indexOf("/", 3) > -1) {
            return `${dataCard} Hora: Não Definida`
          }
          if(dataCard.indexOf("-", 3) > -1){
            const dataBanco = new Date(dataCard)
            let ano = dataBanco.getFullYear();
            let mês = (dataBanco.getMonth() + 1).toString().padStart(2, '0');
            let dia = dataBanco.getDate().toString().padStart(2, '0');
            let hora = dataBanco.getHours().toString().padStart(2, '0');
            let min =  dataBanco.getMinutes().toString().padStart(2, '0');
            return `Venc. ${dia}/${mês}/${ano} às: ${hora}:${min}`
          }
        }
      },
    quantFase() {
      return (cards) => cards.length;
    },
    antesVencimento() {
      return (data) => {
        return this.definirCor(data);
      };
    },
    venceu() {
      return (data) => {
        if (new Date(this.parseDate(data)) < new Date()) {
          return true;
        } else {
          return false;
        }
      };
    },
    resumoCalcDiasRestante() {
      return (data) => {
        return this.calcDiasCard(data);
      };
    },
  },
  methods: {
    inseridoNovoTitulo(event){
      this.dataCards.titulo = event
    },
    formatDataAlterada(dataFinal) {
    const dataOportunidade = new Date(dataFinal).toISOString().substring(0, 10);
    let hora = dataFinal.getHours().toString().padStart(2, '0');
    let min = dataFinal.getMinutes().toString().padStart(2, '0');
    const [year, month, day] = dataOportunidade.split("-");
    return `${year}-${month}-${day}T${hora}:${min}`;
      },

      formatData(data) {
        if (!data) return null;
        return this.formatDataAlterada(data);
      },
      
    filtrarcards(strCard) {
      if (strCard === "" || strCard == null || strCard == undefined) {
        this.fases.forEach((ele) => {
          ele.cards.forEach((card) => {
            card.pesquisa = false;
          });
        });
        return;
      }
      this.fases.forEach((ele) => {
        ele.cards.forEach((card) => {
          if (
            card.titulo
              .toLowerCase()
              .normalize("NFD")
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "")
              .includes(
                strCard
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "")
              )
          ) {
            card.pesquisa = false;
          } else {
            card.pesquisa = true;
          }
        });
      });
    },
    historicoDFaseNoCard(card, nomeFase) {
      card.hFase.unshift({
        faseAnterior:
          card.hFase.length > 0 ? card.hFase[0].nomeFase : "Fase Inicial",
        autor: this.dadosUser.name,
        nomeFase: nomeFase,
        dataHora: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        ).toISOString(),
      });
      return card;
    },
    goCardViaLink(dados) {
      let dadosEnvioMover = {};
      dadosEnvioMover.fase = dados.idFaseD;
      dadosEnvioMover.id = dados.idCard;

      //Removendo card na posição da FASE DE SAÍDA
      this.fases[dados.pFaseS].cards.splice(dados.pCard, 1);

      //Modificando card para a fase de DESTINO
      this.fases[dados.pFaseD].cards.splice(0, 0, dados.data);

      dadosEnvioMover.historico = this.historicoDFaseNoCard(
        this.fases[dados.pFaseD].cards[0].historico,
        this.fases[dados.pFaseD].nome
      );

      axios
        .patch(`${urlApi}/card/${dados.idCard}`, dadosEnvioMover)
        .then((res) => {
          this.fases[dados.pFaseD].cards[0].fase = dados.idFaseD;
          // ordenar por vencimento
          this.fases[dados.pFaseD].cards = this.ordenarCards(
            this.fases[dados.pFaseD].cards,
            dados.pFaseD
          );
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
    openMoverCard(event) {
      this.nomeEIdFases = Object.assign({}, event);
      this.dialogMoverCards = true;
    },
    verificarFasesPermitidas(idAtual, idAnterior) {
      for (let fasesAutorizadas of this.fases[idAnterior].opcoes
        .fasesPermitidas) {
        if (idAtual === fasesAutorizadas) {
          this.isPasso = true;
          return;
        } else {
          this.msgNaoAutorizado = `Na fase ${this.fases[idAnterior].nome} a licitação não pode ir para ${this.fases[idAtual].nome} `;
          this.isPasso = false;
        }
      }
    },
    atOpMoverCardBd(op) {
      this.fases[op.posicaoFaseAtual].opcoes = Object.assign(
        {},
        op.OpFaseAtual
      );
      axios
        .post(`${urlApi}/atualizarCamposFase`, op.OpFaseAtual)
        .then()
        .catch(showError);
    },
    definirMoverCards(posicaoFaseAtual, nomeFaseAtual, idFaseAtual, op) {
      let objNomesEIdFases = {};
      let arrDeNomesFases = [];

      objNomesEIdFases.NomeFaseAtual = nomeFaseAtual;
      objNomesEIdFases.posicaoFaseAtual = posicaoFaseAtual;
      objNomesEIdFases.idFaseAtual = idFaseAtual;
      objNomesEIdFases.OpFaseAtual = op;

      for (let { nome: nomeFase } of this.fases) {
        arrDeNomesFases.push({ nomeFase });
      }
      objNomesEIdFases.todasFases = arrDeNomesFases;
      this.nomeEIdFases = objNomesEIdFases;
      this.dialogMoverCards = true;
    },
    isFiltro(colDFases, IdInicial, IdFinal) {
      let inicial = IdInicial;
      let final = IdFinal - 1;
      while (inicial <= final) {
        if (
          colDFases[inicial].opcoes !== null &&
          colDFases[inicial].opcoes !== undefined
        ) {
          if (colDFases[inicial].opcoes.filtrosFase.ativo) {
            this.quantFiltros++;
          }
        }
        inicial++;
      }
      if (this.quantFiltros > 0) {
        this.msgNaoAutorizado =
          "Existe fases com filtros que você precisa definir";
        this.isPasso = false;
      } else {
        this.isPasso = true;
      }
      this.quantFiltros = 0;
    },
    modificarOrdenacaoCards(pFase, idFase) {
      this.fases[pFase].opcoes.porMaisAntigo =
        !this.fases[pFase].opcoes.porMaisAntigo;
      this.ordenarCards(this.fases[pFase].cards, pFase);

      let atOpFaseOrdenacao = Object.assign({}, this.fases[pFase].opcoes);
      axios
        .post(`${urlApi}/atualizarCamposFase`, atOpFaseOrdenacao)
        .then()
        .catch(showError);
    },
    ordenarCards(cards, posicaoFase) {
      if (this.fases[posicaoFase].opcoes.porMaisAntigo) {
        return cards.sort((a, b) => {
          return (
            new Date(this.parseDate(a.vencimento)).getTime() -
            new Date(this.parseDate(b.vencimento)).getTime()
          );
        });
      } else {
        return cards.sort((a, b) => {
          return (
            new Date(this.parseDate(b.vencimento)).getTime() -
            new Date(this.parseDate(a.vencimento)).getTime()
          );
        });
      }
    },
    definirCor(dtCor) {
      let dtaHoje = new Date().toISOString();
      let istaDataAgoraCor = this.$moment(dtaHoje);
      let istaDataRecebidaCor = this.$moment(this.parseDate(dtCor));

      if (istaDataAgoraCor.isSame(istaDataRecebidaCor, "day")) {
        return "decima";
      } else if (!istaDataAgoraCor.isBefore(istaDataRecebidaCor)) {
        return "error";
      } else {
        return "segunda";
      }
    },
    calcDiasCard(dataRecebida) {
      let dataHoje = new Date().toISOString();
      let istaDataAgora = this.$moment(dataHoje);
      let istaDataRecebida = this.$moment(this.parseDate(dataRecebida));

      if (istaDataAgora.isSame(istaDataRecebida, "day")) {
        return "Vence hoje!";
      } else if (!istaDataAgora.isBefore(istaDataRecebida)) {
        return `Vencida ${istaDataRecebida.from(istaDataAgora)}`;
      } else {
        return `Vence ${istaDataRecebida.from(istaDataAgora)}`;
      }
    },
    resetarOp(opReset) {
      this.fases[opReset.pFase].cards.filter((card) => {
        if (card.opcoesFase !== null) {
          if (opReset.op == "checkbox") {
            card.opcoesFase.filter((op) => {
              if (op !== null) {
                op[opReset.op] = [];
              }
            });
          } else {
            card.opcoesFase.filter((op) => {
              if (op !== null) {
                op[opReset.op] = [];
              }
            });
          }
          let resetOpNoCard = {};
          resetOpNoCard.id = card.id;
          resetOpNoCard.opcoesFase = card.opcoesFase;
          axios
            .post(`${urlApi}/resetarOpCard`, resetOpNoCard)
            .then((res) => {})
            .catch(showError);
        }
      });
    },
    removerCard(idCard, pCard, pFase) {
      axios
        .delete(`${urlApi}/card/${idCard}`)
        .then((res) => {
          this.fases[pFase].cards.splice(pCard, 1);
          this.dialogoViewsDataCard = false;
          this.$toasted.global.defaultSuccess({ msg: "Card removido" });
        })
        .catch(showError);
    },
    atDadosNovosCards(dadosNovos) {
      this.fases[dadosNovos.pfase].cards[dadosNovos.pCardFase][
        dadosNovos.item
      ] = dadosNovos[dadosNovos.item];
    },
    atCard() {
      let novosDadosCard = {};
      novosDadosCard = this.cardClicadoAtual;
      axios
        .post(`${urlApi}/card/atCard`, novosDadosCard)
        .then((res) => {
          res.data[0].vencimento = this.parseDate(res.data[0].vencimento);
          this.cardClicadoAtual = res.data[0];
          this.$toasted.global.defaultSuccess({ msg: "Card Atualizado" });
        })
        .catch(showError);
    },
    validarCampos(val) {
      if (val == null || undefined) {
        return false;
      } else {
        return val;
      }
    },
    fecharOpFases(reset) {
      if (!reset) {
        this.dialogOpFase = false;
      } else {
        this.fases[reset.posicaoDaFase].opcoes = reset;
        this.dialogOpFase = false;
      }
    },
    novosAnexos(dadosAnexo) {
      this.fases[dadosAnexo.pfase].cards[dadosAnexo.pCardFase].anexo =
        dadosAnexo.novosAnexos;
    },
    novasOpFase(op) {
      this.fases[op.posicaoFase].nome = op.nomeFase;
      this.fases[op.posicaoFase].opcoes = Object.assign({}, op);
    },
    opFases(nomeFase, posicaoDaFase, idFase, opFaseClicada) {
      this.nomeFaseOpcoes = nomeFase;
      this.posicaoDaFase = posicaoDaFase;
      this.idFaseClicada = idFase;
      this.opFaseClicada = this.validarCampos(opFaseClicada);
      this.dialogOpFase = true;
    },
    viewsDataCard(card, opFases, nomeFase, pFaseLayout, pCardFase) {
      let getTodasFases = [];
      for (let {
        id: id,
        nome: nomeFase,
        opcoes: { posicaoFase: posicaoFase },
      } of this.fases) {
        getTodasFases.push({ id, nomeFase, posicaoFase });
      }
      this.getTodasFasesBd = getTodasFases;
      this.posicaoCardViews = pCardFase;
      this.posicaoFaseViews = pFaseLayout;

      if (card.opcoesFase === null || card.opcoesFase === undefined) {
        card.opcoesFase = [];
      }
      if (
        card.opcoesFase[card.fase] === null ||
        card.opcoesFase[card.fase] === undefined
      ) {
        card.opcoesFase[card.fase] = {
          historico: [],
        };
      }

      if (typeof card.atividades === "string") {
        card.atividades = [];
      }
      // VERIFICAÇÃO DOS CAMPOS OBRIGATÓRIOS
      if (
        card.opcoesFase[card.fase].checkbox === null ||
        card.opcoesFase[card.fase].checkbox === undefined
      ) {
        card.opcoesFase[card.fase].checkbox = [];
      }

      if (
        card.opcoesFase[card.fase].radio === null ||
        card.opcoesFase[card.fase].radio === undefined
      ) {
        card.opcoesFase[card.fase].radio = [];
      }

      this.viewsDataCardClicado = card;
      this.viewsOpFaseClicada = opFases;
      this.nomeFaseClicada = nomeFase;
      this.dialogoViewsDataCard = true;
    },
    addNovoCard() {
      const dataHoje = new Date(Date.now() - new Date().getTimezoneOffset())
      const vencimento = this.dataCardBd(this.dataCards.vencimento)
      let ano = dataHoje.getFullYear();
      let mês = (dataHoje.getMonth() + 1).toString().padStart(2, '0');
      let dia = dataHoje.getDate().toString().padStart(2, '0');
      let hora = dataHoje.getHours().toString().padStart(2, '0');
      let min =  dataHoje.getMinutes().toString().padStart(2, '0');

      this.dataCards.fase = 1;
      this.dataCards.userId = this.dadosUser.id;
      /* this.dataCards.vencimento = this.dateFormatted; */
      this.dataCards.vencimento = this.dataEhoraCard;
      this.dataCards.anexo = JSON.stringify(this.payloadFilesUpados);
      this.dataCards.permissao = JSON.stringify(this.emailComPermissoes);
      this.dataCards.atividades = JSON.stringify([]);
      this.dataCards.historico = { hFase: [] };

      this.dataCards.historicoFi = `Licitação criada em ${dia}/${mês}/${ano} às ${hora}:${min} | por ${this.dadosUser.name} | Com número:${this.dataCards.titulo} | Com ${vencimento}`;
      axios
        .post(`${urlApi}/card`, this.dataCards)
        .then((res) => {
          this.dataCards.anexo = this.payloadFilesUpados;
          this.dataCards.id = res.data[0].id;
          this.fases[0].cards.push(this.dataCards);
          this.emailComPermissoes = [];
          this.payloadFilesUpados = [];
          this.novaLicitacao = false;
          this.$toasted.global.defaultSuccess();
          this.dataCards = {};
        })
        .catch(showError);
    },
    definirPermissoes() {
      if (this.userMarcados.length > 0)
        this.userMarcados.filter((el, idx, arr) => {
          this.emailComPermissoes.push(this.userPermissoes[el].email);
        });
      this.dialogUsuarios = false;
    },
    getUserPermissoes() {
      axios
        .get(`${urlApi}/users`)
        .then((res) => {
          this.userPermissoes = res.data;
        })
        .catch(showError);
    },
    deletarArquivoUpado(caminho, idDelete) {
      let fileDelet = {};
      fileDelet.caminho = caminho;
      axios
        .post(`${urlApi}/deletarFile`, fileDelet)
        .then((res) => {
          this.payloadFilesUpados.splice(idDelete, 1);
          this.$toasted.global.defaultSuccess(res.data);
        })
        .catch(showError);
    },
    definirIcone(tipo) {
      switch (tipo) {
        case "image/png":
        case "image/jpeg":
        case "image/webp":
          return "image";
          break;
        case "application/pdf":
          return "picture_as_pdf";
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "text/plain":
        case "application/vnd.ms-excel":
          return "summarize";
          break;
        default:
          return "link";
      }
    },
    abrirArquivo(caminho) {
      let url = `${urlApi}/card/path?caminho=${caminho}&key=${this.dadosUser.token}&action=verLink`;
      axios
        .get(url)
        .then((res) => {
          window.open(url);
        })
        .catch(showError);
    },
    arquivosUpados(files) {
      files.filter((el, idx, arr) => {
        this.payloadFilesUpados.push({
          caminho: el.newFilename,
          nomeArquivo: el.originalFilename,
          icone: this.definirIcone(el.type),
          tipo: el.type,
          coluna: el.coluna,
          nomeCampo: el.nomeCampo,
          lastModifiedDate: el.lastModifiedDate,
          idFase: el.idFase,
        });
      });
    },
    fileUpload(coluna, nomeCampo) {
      let formData = new FormData();
      this.files.filter((ele, index, array) => {
        formData.append(ele.name, ele);
      });
      formData.append("coluna", coluna);
      formData.append("nomeCampo", nomeCampo);
      formData.append("idFase", "1");
      axios
        .post(`${urlApi}/UpArquivo`, formData)
        .then((res) => {
          this.arquivosUpados(res.data);
          this.$toasted.global.defaultSuccess();
        })
        .catch(showError);
    },
    formatDate(dataVencimento) {
      if (!dataVencimento) return null;
      const [year, month, day] = dataVencimento.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(dataVencimento) {
      if (!dataVencimento) return null;
      if (dataVencimento.indexOf("/", 3) > -1) {
        const [day, month, year] = dataVencimento.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      } else {
        return dataVencimento;
      }
    },
    removerFases() {
      if (this.fases[this.fases.length - 1].cards.length === 0) {
        if (this.fases.length !== 1) {
          axios
            .delete(`${urlApi}/fase/${this.fases[this.fases.length - 1].id}`)
            .then((res) => {
              this.fases.pop();
              this.$toasted.global.defaultSuccess();
            })
            .catch(showError);
        }
      }
    },
    corrigindoIdFase(idCorreto, op) {
      op.idFase = idCorreto;
      axios.post(`${urlApi}/atualizarCamposFase`, op);
    },
    fecharDialogoAddTituloFrase() {
      this.dialogAddTituloFrase = false;
      if (this.dataFases.nome !== undefined) {
        // enviado possível ultimo id nas opções, na volta o id é corrigido.
        let possivelId = this.fases[this.fases.length - 1].id + 1;
        this.dataFases.opcoes = {
          idFase: possivelId,
          posicaoFase: this.fases.length,
        };
        axios
          .patch(`${urlApi}/fase`, this.dataFases)
          .then((res) => {
            if (res.data[0].id !== this.dataFases.opcoes) {
              this.corrigindoIdFase(res.data[0].id, res.data[0].opcoes);
            }
            this.fases.push(res.data[0]);
            this.$toasted.global.defaultSuccess();
          })
          .catch(showError);
        this.dataFases = {};
      }
    },
    eventoReady(id, evento) {
      if (evento.removedIndex !== null) {
        this.removerEAdd.removedIndex = evento.removedIndex;
      }

      this.removerEAdd.addedIndex = evento.addedIndex;
      this.removerEAdd.fase = this.draggingCard.idFase;

      if (id > this.draggingCard.idFase + 1) {
        this.isFiltro(this.fases, this.draggingCard.idFase, id);
      } else {
        this.isPasso = true;
      }
      if (id === this.draggingCard.idFase) {
        this.isMesmoCt = true;
      } else {
        this.isMesmoCt = false;
      }
      if (id < this.draggingCard.idFase) {
        this.filtroFase = false;
      }
      if (id > this.draggingCard.idFase) {
        if (this.fases[this.draggingCard.idFase].opcoes.filtrosFase.ativo) {
          this.filtroFase = true;
        } else {
          this.filtroFase = false;
        }
      }
      if (id !== this.draggingCard.idFase && this.isPasso && !this.filtroFase) {
        this.verificarFasesPermitidas(id, this.draggingCard.idFase);
      }
    },
    handleDragStart(idFase, dadosDoCardSaindo) {
      const { payload, isSource } = dadosDoCardSaindo;
      if (isSource) {
        this.draggingCard = {
          idFase,
          id: payload.id,
          data: {
            ...this.fases[idFase].cards[payload.id],
          },
        };
        let idBdFase = this.fases[idFase].id;

        if (
          this.draggingCard.data.opcoesFase === null ||
          this.draggingCard.data.opcoesFase === undefined
        ) {
          this.draggingCard.data.opcoesFase = [];
        }
        if (
          this.draggingCard.data.opcoesFase[idBdFase] === null ||
          this.draggingCard.data.opcoesFase[idBdFase] === undefined
        ) {
          this.draggingCard.data.opcoesFase[idBdFase] = {
            historico: [],
          };
        }
        if (this.fases[idFase].opcoes.filtrosFase.campo === "checkBox") {
          if (
            this.draggingCard.data.opcoesFase[idBdFase].checkbox === null ||
            this.draggingCard.data.opcoesFase[idBdFase].checkbox === undefined
          ) {
            this.draggingCard.data.opcoesFase[idBdFase].checkbox = [];
          }
        }
        if (this.fases[idFase].opcoes.filtrosFase.campo === "radio") {
          if (
            this.draggingCard.data.opcoesFase[idBdFase].radio === null ||
            this.draggingCard.data.opcoesFase[idBdFase].radio === undefined
          ) {
            this.draggingCard.data.opcoesFase[idBdFase].radio = [];
          }
        }
        if (this.fases[idFase].opcoes.filtrosFase.campo === "ambos") {
          if (
            this.draggingCard.data.opcoesFase[idBdFase].radio === null ||
            this.draggingCard.data.opcoesFase[idBdFase].radio === undefined
          ) {
            this.draggingCard.data.opcoesFase[idBdFase].radio = [];
          }
          if (
            this.draggingCard.data.opcoesFase[idBdFase].checkbox === null ||
            this.draggingCard.data.opcoesFase[idBdFase].checkbox === undefined
          ) {
            this.draggingCard.data.opcoesFase[idBdFase].checkbox = [];
          }
        }
      }
    },
    handleDrop(idFase, dadosDoCardChegando) {
      // Este evento handleDrop é executado em todas as fases
      //  idFase é o indice(ID) de todas as fases (dentro do IF abaixo *addedIndex !== null* o idFase é o indice(ID) da fase atual)
      //  this.draggingCard.idFase é o indice(ID)(frontEnd) da fase de onde foi removido o card
      //  this.draggingCard.data é onde estão os dados do card que está sendo movido
      const { removedIndex, addedIndex, payload } = dadosDoCardChegando;
      //  addedIndex é o novo indice(ID) do card dentro da coluna
      //  removedIndex é o indice(ID) do card dentro da coluna antes da remoção (ou da onde foi removido)(ou ainda indice(id) atual do card)
      //  dentro do dadosDoCardChegando também tem o payload que é o indice(ID) do card antes de ser movido verticalmente
      if (idFase === this.draggingCard.idFase && removedIndex === addedIndex) {
        return;
      }
      if (idFase === this.draggingCard.idFase && this.isMesmoCt) {
        if (removedIndex !== null && this.isMesmoCt) {
          // removendo o card da posição do removedIndex
          this.fases[idFase].cards.splice(removedIndex, 1);
        }
        if (addedIndex !== null) {
          this.fases[idFase].cards.splice(
            addedIndex,
            0,
            this.draggingCard.data
          );
          this.fases[idFase].cards = this.ordenarCards(
            this.fases[idFase].cards,
            idFase
          );
          return;
        }
      }

      if (addedIndex !== null) {
        let posicaoFaseAnterior = this.draggingCard.idFase;
        let idFaseAnterior = this.draggingCard.data.fase;

        if (this.filtroFase) {
          let campoDoFiltro =
            this.fases[posicaoFaseAnterior].opcoes.filtrosFase.campo;

          if (campoDoFiltro === "checkBox") {
            if (
              this.draggingCard.data.opcoesFase[idFaseAnterior].checkbox
                .length === 0
            ) {
              this.$toasted.global.defaultError({
                msg: this.fases[posicaoFaseAnterior].opcoes.filtrosFase
                  .mensagem,
              });
              this.filtroFase = true;
              return;
            } else {
              this.filtroFase = false;
            }
          } else if (campoDoFiltro === "radio") {
            if (
              this.draggingCard.data.opcoesFase[idFaseAnterior].radio.length ===
              0
            ) {
              this.$toasted.global.defaultError({
                msg: this.fases[posicaoFaseAnterior].opcoes.filtrosFase
                  .mensagem,
              });
              this.filtroFase = true;
              return;
            } else {
              this.filtroFase = false;
            }
          } else {
            if (
              this.draggingCard.data.opcoesFase[idFaseAnterior].radio.length ===
              0
            ) {
              this.$toasted.global.defaultError({
                msg: this.fases[posicaoFaseAnterior].opcoes.filtrosFase
                  .mensagem,
              });
              this.filtroFase = true;
              return;
            } else if (
              this.draggingCard.data.opcoesFase[idFaseAnterior].checkbox
                .length === 0
            ) {
              this.$toasted.global.defaultError({
                msg: this.fases[posicaoFaseAnterior].opcoes.filtrosFase
                  .mensagem,
              });
              this.filtroFase = true;
              return;
            } else {
              this.filtroFase = false;
            }
          }
        }
      }

      if (addedIndex !== null && !this.filtroFase) {
        let idsCard = Object.assign({}, this.removerEAdd);
        // Remover Card
        this.fases[idsCard.fase].cards.splice(idsCard.removedIndex, 1);
        // Add Card
        if (this.isPasso) {
          this.fases[idFase].cards.splice(
            addedIndex,
            0,
            this.draggingCard.data
          );

          this.cardFaseAlterada.id = this.draggingCard.data.id;
          this.cardFaseAlterada.fase = this.fases[idFase].id;

          if (
            !Array.isArray(this.fases[idFase].cards[addedIndex].historico.hFase)
          ) {
            this.fases[idFase].cards[addedIndex].historico = {
              hFase: [],
            };
          }
          //Registrando historico de movimento do card
          this.cardFaseAlterada.historico = this.historicoDFaseNoCard(
            this.fases[idFase].cards[addedIndex].historico,
            this.fases[idFase].nome
          );

          // ordenar por vencimento
          this.fases[idFase].cards = this.ordenarCards(
            this.fases[idFase].cards,
            idFase
          );

          // Alterar fase do card
          axios
            .patch(
              `${urlApi}/card/${this.cardFaseAlterada.id}`,
              this.cardFaseAlterada
            )
            .then((res) => {
              this.fases[idFase].cards[addedIndex].fase = this.fases[idFase].id;
              this.cardFaseAlterada = {};
              this.$toasted.global.defaultSuccess();
            })
            .catch(showError);
        } else {
          this.fases[idsCard.fase].cards.splice(
            idsCard.removedIndex,
            0,
            this.draggingCard.data
          );
          this.$toasted.global.defaultError({
            msg: this.msgNaoAutorizado,
          });
          return;
        }
      }
    },
    getChildPayload(id) {
      return {
        id,
      };
    },
    inicializar(ativarModoBkp) {
      this.looad = true
      const ativaModoBkp = { bkp: ativarModoBkp }
      axios
        .post(`${urlApi}/fase`, ativaModoBkp)
        .then((res) => {
          res.data.forEach((e) => {
            e.cards.forEach((e) => {
              e.pesquisa = false;
            });
          });
          this.fases = res.data;
          this.$toasted.global.defaultSuccess();
          this.looad = false
        })
        .catch(showError);
    },
  },
  created() {
    this.inicializar();
    barramento.getNovalicitacao((a) => {
      this.novaLicitacao = true;
    });
    barramento.getAddFase((a) => {
      this.dialogAddTituloFrase = true;
    });
    barramento.getRemoverFases((a) => {
      this.removerFases();
    });
    barramento.getModoEdicao((modo) => {
      this.ativarModoEdicao = modo;
    });
    barramento.getModoBkp((modoBkp) => {
      this.inicializar(modoBkp);

    });
    barramento.getPesquisaCard((pesquisa) => {
      this.filtrarcards(pesquisa);
    });
  },
};
</script>

<style>
.u-hide {
  display: none !important;
}
.c-fases-ct {
  flex-flow: column nowrap;
  justify-content: start;
  align-items: center;
  align-content: center;
}
.c-fases-ct aside {
    background: #ffffff;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 17%);
}
.c-dados-cards .theme--light.v-icon,
.c-dados-cards label,
.c-dados-cards fieldset,
.c-dados-cards .v-messages.theme--light {
  color: white !important;
}
.c-dados-cards .v-text-field__slot input {
  color: rgb(255 255 255 / 88%) !important;
}
.placeholder {
  background: #d3d9e5;
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  border: dashed 1px #a1a1a1;
}
.c-titulo-fase__caixa {
  position: sticky;
  z-index: 10;
  top: 0px;
  margin: 0px;
  display: block;
  padding: 5px;
  padding-top: 10px;
}

.c-titulo-fase span.c-titulo-fase__titulo {
  display: block;
  text-align: center;
  padding: 5px;
  font-size: 12px;
  text-transform: uppercase;
  border: solid 1px #363494;
  border-radius: 40px;
  font-weight: 700;
}
.calcDiasRestante {
  text-align: center;
  background: #363494;
  color: #f2f2f2;
  border-radius: 20px;
}
.c-dados-cards__anexo button {
  background: #363494;
  min-width: 40px;
  height: 41px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 32%);
}
.c-dados-cards__anexo_col {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  height: 88px;
  margin-top: 4px;
}
.c-dados-cards__anexo_col p {
  font-size: 0.6rem;
  padding-top: 6.5px;
  margin-left: -9.5px;
}
.c-dados-cards__anexo button:after {
  display: none !important;
}
.c-dados-cards__anexo .mdi:before {
  color: #f2f2f2;
}
.c-dados-cards__vencimento {
  background: #363494;
  border-radius: 15px 15px 0px 0px;
  padding-top: 23px;
  height: 70px;
  padding-left: 20px;
}
.c-dados-cards__vencimento * {
  color: #f2f2f2 !important;
}
.c-dados-cards__permissao i,
.c-dados-cards__etiquetas i {
  margin: 0px !important;
  font-size: 20px !important;
}
.c-dados-cards__permissao,
.c-dados-cards__etiquetas {
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: calc(27px);
}
.c-dados-cards__permissao p,
.c-dados-cards__etiquetas p {
  font-size: 0.6rem;
  padding-top: 3px;
  text-align: center;
}
.c-dados-card {
  border-radius: 15px !important;
}
.bgCardDrop {
  background: transparent;
}
.draggable-item p {
  font-size: 0.9rem;
  text-align: left;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  letter-spacing: 0;
}
.c-card-resumo_data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-card-resumo_data p {
  color: white;
  min-width: 190px;
  border-radius: 50px;
  padding: 5px;
  padding-left: 7px;
  font-size: 12px;
  font-weight: 600;
  margin-right: 5px;
}
.c-card-resumo_data span {
  font-size: 11px;
  font-weight: 600;
}
.haVencer {
  background: #363494;
}
.venceu {
  background: #ff3131;
}
.c-card {
  cursor: pointer;
}
.c-views-cards__dialog {
  margin: 7px !important;
  max-height: 100% !important;
  height: 98% !important;
}
.c-btn-remover-card {
  position: absolute;
  width: 10%;
  height: 21px;
  right: 0px;
}
.c-mover-card-dalog,
.c-mover-card-ct {
  border-radius: 15px !important;
}
.c-titulo-fase__quant {
    background: #353492;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    top: -1px;
    text-align: center;
    width: 23px;
    box-shadow: 0px 0px 3px 1px black;
    font-weight: 900;
    color: white;
    z-index: 2;
    padding-top: 2px;
    height: 23px;
    transform: scale(0.6);
}
.c-titulo-fase__icon {
  display: block;
  text-align: center;
}
.is_fase_backup {
  opacity: 0.9;
}
.is_fase_backup .c-card {
  background: rgb(48 66 100 / 23%);
}
.is_fase_backup span.c-titulo-fase__titulo {
  border: none;
}
.is_fase_backup .c-card * {
  opacity: 0.8;
}
.is_fase_backup .c-titulo-fase__caixa:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #d5deeb;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
}
.c-card_atrasado {
  background: #ffe3c6;
  display: inline-flex;
}
p.c-card_atrasado {
  color: #ff9800;
  padding: 5px 10px;
  border-radius: 15px;
}
.c-card-rs_tt__cp {
  font-size: 13px;
  color: #363494;
  font-weight: 500;
}
.c-card-rs_tt__tt {
  display: block;
  font-size: 13px;
  color: #ab3331;
  font-weight: 700;
  padding-left: calc(9%);
}
p.c-card-tt {
    font-size: 13px;
    line-height: 14px;
    color: #363494;
    font-weight: 900;
    letter-spacing: 0.6px;
}
.c-dados-cards__vencimento input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    width: 53px!Important;
    font-size: 25px;
    cursor: pointer;
    filter: invert(1);
}
</style>
