<template>
  <v-textarea
    v-model="cAddComentario"
    class="c-comentario-cp"
    color="segunda"
    clearable
    filled
    dense
    prepend-inner-icon="add_comment"
    placeholder="Escreva seu comentário..."
    :row-height="alturaComentario"
    :row="1"
    auto-grow
    @focus="focusCometario()"
    @blur="blurCometario()"
  >
    <template #append-outer>
      <v-icon
        v-if="cAddComentario.length !== 0"
        color="segunda"
        @click="ePublicarComentario()"
      >
        save
      </v-icon>
    </template>
  </v-textarea>
</template>

<script>
export default {
  name: "AdicionarComentario",
  data: function() {
    return {
      alturaComentario: "8",
      cAddComentario: ""
    };
  },
  methods: {
      ePublicarComentario(){
          let sendComentario = this.cAddComentario
          this.$emit("receberComentario", sendComentario);
          this.cAddComentario = ""
      },
    focusCometario() {
      this.salvarComentario = "save";
      this.alturaComentario = "24";
    },
    blurCometario() {
      this.alturaComentario = "8";
    }
  }
};
</script>

<style>
</style>