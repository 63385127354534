<template>
  <div>
    <v-alert
      v-if="sessaoExpirada"
      class="mb-10"
      border="right"
      colored-border
      type="error"
      elevation="2"
      dismissible
    >
      Sua sessão expirou!
      Por motivos de segurança sua sessão possui um prazo de três dias, para renovar seu prazo basta entrar com suas credenciais novamente.
    </v-alert>
    <v-slide-y-transition appear>
      <v-row class="d-flex justify-center pb-10">
        <v-col
          cols="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          justify="center"
          align-content="center"
          align="center"
          class="c-gestor-projeto-login mb-0 pb-0 mb-n4"
        >
          <v-img
            class="c-header__logo mb-0 pb-0"
            src="@/assets/intereventos.svg"
            contain
            max-height="auto"
            max-width="80"
          />
          <v-col
            justify="right"
            align-content="right"
            align="right"
            class="c-gestor-projeto-login-titulo pa-0 ma-0"
          >
            <p class="text-overline text-center ma-0 py-3 c-nome-systema">
              Gestor de Projetos Intereventos
            </p>
          </v-col>
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <v-slide-y-reverse-transition appear>
      <v-card
        class="mx-auto"
        max-width="344"
        flat
        elevation="20"
        shaped
      >
        <v-row class="d-flex flex-column pa-5">
          <v-col>
            <v-text-field
              v-model="user.email"
              color="segunda"
              type="email"
              name="email"
              append-icon="email"
              :rules="[rules.required, rules.email]"
              label="E-mail"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="user.password"
              color="segunda"
              :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="trocarIconeLogin ? 'text' : 'password'"
              name="password"
              label="Senha"
              class="input-group--focused"
              @click:append="trocarIconeLogin = !trocarIconeLogin"
              @keyup.enter="signin"
            />
          </v-col>

          <v-col class="d-flex justify-end mt-n5">
            <v-btn
              color="#224BFB"
              class="white--text"
              @click="signin"
              @dblclick.prevent
            >
              ENTRAR
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey } from "@/global";
import barramento from "@/barramento";
export default {
  name: "CardDeLogin",
  props: {
    sessaoExpirada: Boolean
  },
  data: function() {
    return {
      user: {},
      trocarIconeLogin: false,
      rules: {
        required: value => !!value || "Campo obrigatório.",
        min: v => {
          if (v == undefined) {
            return "Digite uma senha";
          } else {
            return v.length >= 8 || "Min de 8 caracteres";
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        }
      }
    };
  },

  methods: {
    signin() {
      axios
        .post(`${urlApi}/signin`, this.user)
        .then(res => {
          localStorage.setItem(userKey, JSON.stringify(res.data));
          axios.defaults.headers.common[
            "Authorization"
          ] = `bearer ${res.data.token}`;
          this.$toasted.global.defaultSuccess();
          barramento.enviarStatusLogin(true, false);
        })
        .catch(showError);
    }
  }
};
</script>
<style>
.c-nome-systema {
    line-height: 15px!important;
}
.c-gestor-projeto-login .c-gestor-projeto-login-titulo {
  color: #ffffff;
  font-weight: 500;
}
</style>