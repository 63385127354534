<template>
  <v-data-table
    :headers="headers"
    :items="infoProdutoPregao = produtosCadastrados"
    item-key="id"
    sort-by="Produto"
    class="mt-5 c-table-pregao"
    :loading="loading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          hint="Busque Por: Nome"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar produto"
          single-line
        ></v-text-field>
         <v-spacer></v-spacer>
        <v-currency-field
            outlined
            readonly
            label="Valor do Pregão"
            prefix="Saldo: R$"
            :value="valorTotalGeral"
          ></v-currency-field>
          <div class="calcHide">
         {{totalGeralValor('valorTotal')}}
          </div>
        <v-dialog v-model="dialogCadastroPregaoProdutos" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="mb-0 pb-0 " cols="12" sm="12" md="12">
                    <v-text-field disabled :value="editedItem.name" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-text-field type="number" outlined v-model="editedItem.idGenericaProduto" label="N°"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-currency-field outlined v-model="editedItem.valorProdutoPregao" label="Adicionar Valor"></v-currency-field>
                  </v-col>
                  <v-col cols="12" sm="7" md="4">
                    <v-text-field type="number" outlined  v-model.number="editedItem.quantidadeProdutoPregao" label="Adicionar Quantidade"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn-cancelar" color="segunda darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="segunda darken-1" text @click="salveListaProdutosPregao">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template #item.imageUrl="{item}">
      <img :src="item.imageUrl" alt="Imagem" width="70px" height="auto" />
    </template>
    <template #item.idGenerica="{item}">
      N°{{item.idGenerica == null ? ':' : item.idGenerica}} 
    </template>
    <template #item.valorProdutoPregao="{item}">
      <v-currency-field readonly class="input-moeda" prefix="R$" :value='item.valorProdutoPregao' />
    </template>
    <template #item.quantidadeProdutoPregao="{item}">
      <v-text-field readonly type="number" class="input-quantidade" v-model.number='item.quantidadeProdutoPregao' prefix="Quant."></v-text-field>
    </template>
     <template #item.valorTotal="{item}">
      <v-currency-field readonly type="number" disabled class="input-total" :value="isNaN(item.valorTotal) ? '0,00' : item.valorTotal " prefix="R$ "></v-currency-field>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="text-center d-flex ">
      <v-btn class="mx-2" fab dark x-small color="segunda">
        <v-icon dark  @click="editItem(item)">mdi-pencil</v-icon>
      </v-btn></div>
    </template>
  </v-data-table>
</template>

<script>
import axios from "axios";
import { rutPregao, showError } from "@/global";
import barramento from "@/barramento"

export default {
  name: "ProdutosPregao",
  props:{
    produtosCadastrados: Array
  },
  data: function() {
    return {
      search: "",
      dialogCadastroPregaoProdutos: false,
      headers: [
        { text: "Imagem", align: "start", sortable: false, value: "imageUrl" },
        { text: "Produto", value: "name" },
        { text: "N°Item", value: "idGenericaProduto" },
        { text: "Valor", value: "valorProdutoPregao" },
        { text: "Quantidade", value: "quantidadeProdutoPregao"},
        { text: "Total", value: "valorTotal"},
        { text: "Ação", value: "actions", sortable: false }
      ],

      valorTotalGeral: 0,
      loading: false,
      infoProdutoPregao: this.produtosCadastrados,
      editedIndex: -1,
      delProduto: {},
      editedItem: {},
      defaultItem: {},
      produtos: [],
      produtosPregao: {},
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Adicionar Produto" : "Add Valor & Quantidade";
    },
  },

  watch: {
    dialogCadastroPregaoProdutos(val) {
      val || this.close();
    },
  },

  methods: {
  totalGeralValor(chave) {
       this.valorTotalGeral =  this.infoProdutoPregao.reduce((a, b) => a + (b[chave] || 0), 0)
       barramento.enviadoValorTotalPregao(this.valorTotalGeral)
       return this.valorTotalGeral
    },

    editItem(item) {
      this.editedIndex = this.infoProdutoPregao.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogCadastroPregaoProdutos = true;
    },

    deleteItemConfirm() {
      this.infoProdutoPregao.splice(this.editedIndex, 1);
      this.closeDelete();
      axios.delete(`${rutPregao}/produto/${this.delProduto.id}`, this.delProduto)
      .then(() => this.$toasted.global.defaultSuccess())
      .catch(showError)
    },

    close() {
      this.dialogCadastroPregaoProdutos = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    salveListaProdutosPregao() {
      if (this.editedIndex > -1) {
        Object.assign(this.infoProdutoPregao[this.editedIndex], this.editedItem) 
        } 
        this.infoProdutoPregao[this.editedIndex].listaIdPregao = '0'
        this.infoProdutoPregao[this.editedIndex].quantEmpenho = 0
        this.infoProdutoPregao[this.editedIndex].quantParaNota = 0
        this.infoProdutoPregao[this.editedIndex].registroEmissaoNf = 0
        this.infoProdutoPregao[this.editedIndex].valorTotal = this.infoProdutoPregao[this.editedIndex].quantidadeProdutoPregao * this.infoProdutoPregao[this.editedIndex].valorProdutoPregao
        
        this.produtos[this.editedIndex] = this.infoProdutoPregao[this.editedIndex]
        barramento.enviadoProdutosPregao(this.produtos)
        this.close();
    }
  },
};
</script>

<style>
.input-moeda, .input-quantidade {
    width: 150px!important;
}
.input-total{
  width: 100px;
}
.calcHide{
  display: none;
}
</style>