<template>
  <v-list dense>
    <v-list-item-group
      v-model="selecComent"
      color="segunda"
    >
      <v-list-item
        v-for="(comentario, idComentario) in apenasComentario(comentarioCard)"
        :key="idComentario"
      >
        <v-list-item-icon>
          <v-icon color="segunda">
            question_answer
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="text-body-1 font-weight-black segunda--text mt-2"
            v-text="comentario.autor"
          />
          <v-list-item-subtitle
            class="py-2 pl-2 c-atividade-comentario"
            v-text="comentario.comentario"
          />
          <v-divider />
          <div
            class="pt-2"
          >
            {{ comentario.dataHora | moment("add", "3 h", "calendar") }} - Na {{ comentario.fase }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "ListaComentario",
  props: {
    dialogoEventComent: Boolean,
    listaComent: Array
  },
  data: function() {
    return {
      comentarioCard: this.listaComent,
      selecComent: [1]
    };
  },
  computed: {
    apenasComentario() {
      return listAtividades => listAtividades.filter(el => el.isComentario);
    }
  },
  watch: {
    dialogoEventComent(el) {
      if (el) {
        this.comentarioCard = this.listaComent;
      } else {
        this.comentarioCard = [];
      }
    }
  }
};
</script>

<style>
</style>