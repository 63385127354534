<template>
  <v-card class="c-mover-card-ct px-5">
    <v-card-title class="pl-0">
      <v-btn
        absolute
        right
        top
        fab
        small
        class="mt-7 mr-n1"
        @click="clouseMoverCards()"
      >
        <v-icon color="segunda">
          close
        </v-icon>
      </v-btn>
      <h2 class="primeira--text text-h4 font-weight-bold">
        Selecionar Fases
      </h2>
      <h3 class="pl-0 mb-3 segunda--text text-h6 c-texto__ajuda">
        Seus cards na fase
        <span class="font-weight-black text-no-wrap c-texto__ajuda">{{ todasFasesParaMover.NomeFaseAtual }}</span> podem ser movidos para:
      </h3>
    </v-card-title>
    <v-list class="c-lista-fases__parmitidas">
      <v-list-item-group
        v-model="fasesPermitidas"
        color="segunda"
        multiple
        active-class
        @change="fasePermitida($event)"
      >
        <v-list-item
          v-for="(faseParaMover, colFase) in todasFasesParaMover.todasFases"
          :key="colFase"
          class="c-fases-permitidas__item"
          :class="{eventBloqueado: idBloqueio(colFase)}"
        >
          <template #default="{ active, }">
            <v-list-item-content class="pl-5">
              <v-list-item-title v-text="faseParaMover.nomeFase" />
            </v-list-item-content>
            <v-list-item-action
              v-if="colFase !== todasFasesParaMover.posicaoFaseAtual"
              class="pr-10"
            >
              <v-switch
                class="c-views-anexo__isVer pl-4"
                color="segunda"
                :input-value="active"
              />
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "OpMoverCards",
  props: {
    nomeEIdFases: Object,
    isdialogMoverCards: Boolean
  },
  data: function() {
    return {
      todasFasesParaMover: Object.assign({}, this.nomeEIdFases),
      fasesPermitidas: [],
      colInativa: true
    };
  },
  computed: {
    idBloqueio() {
      return idBloc => {
        if (idBloc === this.nomeEIdFases.posicaoFaseAtual) {
          return true;
        } else {
          return false;
        }
      };
    }
  },
  watch: {
    isdialogMoverCards(el) {
      if (el) {
        this.todasFasesParaMover = Object.assign({}, this.nomeEIdFases);
        if (this.todasFasesParaMover.OpFaseAtual.fasesPermitidas.length === 0) {
          this.fasesPermitidas[this.todasFasesParaMover.posicaoFaseAtual] = this.todasFasesParaMover.posicaoFaseAtual;
        } else {
          this.fasesPermitidas = this.todasFasesParaMover.OpFaseAtual.fasesPermitidas;
        }
      } else {
        this.todasFasesParaMover = {};
        this.fasesPermitidas = [];
      }
    },
  },
  mounted() {
    if (this.todasFasesParaMover.OpFaseAtual.fasesPermitidas.length === 0) {
      this.fasesPermitidas[
        this.todasFasesParaMover.posicaoFaseAtual
      ] = this.todasFasesParaMover.posicaoFaseAtual;
    } else {
      this.fasesPermitidas = this.todasFasesParaMover.OpFaseAtual.fasesPermitidas;
    }
  },
  methods: {
    fasePermitida(event) {
      this.todasFasesParaMover.OpFaseAtual.fasesPermitidas = event
      this.atOpMoverCard()
    },
    atOpMoverCard(){
      this.$emit("atOpMoverCard", this.todasFasesParaMover)
    },
    clouseMoverCards() {
      this.$emit("clouseMoverCards");
    },
  }
};
</script>

<style scoped>
.c-texto__ajuda {
    word-break: break-word;
}
.eventBloqueado:after {
  content: "Fase Atual";
  color: white;
  z-index: 9999;
  font-size: 20px;
  line-height: 20px;
  bottom: 0px;
  align-items: center;
  display: flex;
}

.eventBloqueado {
  background: red;
  pointer-events: none !important;
  background: #363494;
  pointer-events: none !important;
  color: white !important;
}
.c-fases-permitidas__item {
  border: solid 2px;
  border-color: #363494;
  margin-bottom: 12px;
  border-radius: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>