<template>
  <v-form>
    <v-row class="custoB d-flex space-between ma-5">
      <v-col cols="12" sm="12" md="4" class="c-relative">
        <v-text-field
          v-if="!isOrgaoCadastrado"
          outlined
          cols="12"
          sm="12"
          md="4"
          hint="Orgão do Pregão"
          v-model="dadosPregao.nomeOrgao"
        >
          <template class="m-p-4" v-slot:label>
            <strong>Novo Orgão</strong>
            <v-icon style="vertical-align: middle">apartment</v-icon>
          </template>
        </v-text-field>

        <v-autocomplete
          v-if="isOrgaoCadastrado"
          v-model="dadosPregao.nomeOrgao"
          :items="orgaosCadastrados"
          auto-select-first
          dense
          chips
          clearable
          deletable-chips
          label="Nome do Órgão"
        >
          <template class="m-p-4" v-slot:label>
            <strong>Nome do Órgão</strong>
            <v-icon style="vertical-align: middle">apartment</v-icon>
          </template>
        </v-autocomplete>
        <v-switch
          color="segunda"
          class="c-absoluto ma-0 pa-0 mt-n2"
          label="Orgão já cadastrado ?"
          v-model="isOrgaoCadastrado"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="12" md="4">
        <v-text-field
          outlined
          cols="12"
          sm="12"
          md="4"
          hint="Código do novo pregão"
          v-model="dadosPregao.idPregao"
        >
          <template class="m-p-4" v-slot:label>
            <strong>ID do Pregão</strong>
            <v-icon style="vertical-align: middle">mdi-key-variant</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-btn
          class="sexta--text"
          block
          x-large
          color="segunda"
          @click="criarPregao"
          v-on:dblclick.prevent
        >
          <v-icon left>mdi-clipboard-check-outline</v-icon>SALVAR PREGÃO
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosPregao.dataInicial"
              label="DATA INICIAL"
              hint="Data inicial do pregão"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosPregao.dataInicial"
            :active-picker.sync="activePicker"
            min="1950-01-01"
            @change="dateSave"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          origin="center center"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              v-model="dadosPregao.dataFinal"
              label="DATA FINAL"
              hint="Data final do pregão"
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dadosPregao.dataFinal"
            :active-picker.sync="activePicker2"
            min="1950-01-01"
            @change="dateSave2"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field v-model="dadosPregao.pneNi" outlined cols="12" sm="12" md="4">
          <template class="m-p-4" v-slot:label>
            <strong>PNE NI</strong>
            <v-icon style="vertical-align: middle">mdi-folder-key-network-outline</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-text-field v-model="dadosPregao.pneHs" outlined cols="12" sm="12" md="4">
          <template class="m-p-4" v-slot:label>
            <strong>PNE HS</strong>
            <v-icon style="vertical-align: middle">mdi-folder-key-network-outline</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <produtosPregao :produtosCadastrados="produtosCadastrados"></produtosPregao>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
import produtosPregao from "./produtosPregao";
import { rutPregao, showError } from "@/global";
import barramento from "@/barramento";
export default {
  name: "CadastroPregao",
  components: { produtosPregao },
  props: {
    passIdPregao: String,
    systemPregao: Object,
    orgaosCadastrados: Array,
    produtosCadastrados: Array
  },

  data: () => ({
    isOrgaoCadastrado: false,
    dadosPregao: {},
    dadosProdutos: {},
    listaProdutos: [],
    activePicker: null,
    activePicker2: null,
    date: null,
    date2: null,
    menu: false,
    menu2: false,
    valorTotalPregao: {},
    idsProdutos: "",
    itemOrgao: [],
    atIdPregao: ''
  }),
  watch: {
    passIdPregao(novoIdPregao){
      this.dadosPregao.idPregao = novoIdPregao
      this.atIdPregao = novoIdPregao
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "Ano"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker2 = "Ano"));
    }
  },
  created() {
    this.dadosPregao.idPregao = this.passIdPregao
    barramento.getProdutosPregao(produtosPregao => {
      this.dadosProdutos = produtosPregao;
    }),
      barramento.getValorTotalPregao(valorTotalPregao => {
        this.valorTotalPregao = valorTotalPregao;
      });
  },
  mounted() {
    this.dadosPregao.idPregao = this.passIdPregao
  },
  methods: {
    limparForm() {
      this.dadosProdutos = {};
      this.dadosPregao = {};
      this.dadosPregao.idPregao = this.atIdPregao
    },
    dateSave(date) {
      this.$refs.menu.save(date);
    },
    dateSave2(date) {
      this.$refs.menu2.save(date);
    },
    criarPregao() {
      try {
        this.listaProdutos = this.dadosProdutos.filter(
          produto => produto !== undefined
        );
      } catch (erroo) {
        showError("Dados do pregão não informado");
      }

      for (this.idsProdutos in this.listaProdutos) {
        this.listaProdutos[
          this.idsProdutos
        ].listaIdPregao = this.dadosPregao.id;
      }

      this.dadosPregao.saldoPregao = this.valorTotalPregao;
      this.dadosPregao.valorEntregue = 0.0;
      this.dadosPregao.listaProdutos = JSON.stringify(this.listaProdutos);

      axios
        .post(`${rutPregao}/pregao`, this.dadosPregao, {
          headers: {
            Authorization: `bearer ${this.systemPregao.sistemPregao.acesso.data.token}`
          }
        })
        .then(res => {
          document.getElementById("js-modal-pregao__close").click();
          this.limparForm();
          this.$toasted.global.defaultSuccess();
          window.open('https://pregao.nutrimix.app', "_blank");
        })
        .catch(showError);
    }
  }
};
</script>

<style>
.form-pregao__item {
  font-family: "Georama", sans-serif !important;
}
</style>